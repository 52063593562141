import {Fragment, useEffect} from 'react';
import {
  XCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import {AbsoluteDate, WEB_CONTEXT} from 'payble-shared';
import {Transition} from '@headlessui/react';
import {markDownToHtml} from 'lib/markDownToHtml';

export const NoticeBanner = ({
  displayEndDate,
  content,
  name,
  isOpen,
  setOpen,
}: {
  displayEndDate: AbsoluteDate;
  content: string;
  name: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  useEffect(() => {
    const today = AbsoluteDate.today(WEB_CONTEXT);
    if (today.isAfterOrEqual(displayEndDate)) return;

    const hideBanner = window.localStorage.getItem(name);
    if (!hideBanner) {
      setOpen(true);
    }
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className="sticky z-50 flex justify-between w-full p-4 shadow-sm bg-yellow-50 border border-yellow-100 text-yellow-700">
        <div className="flex items-center mx-auto">
          <p className="flex items-center text-sm md:text-md font-medium">
            <span className="inline-flex p-1 me-3 items-center justify-center flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-yellow-700"
                aria-hidden="true"
              />
            </span>
            <span
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: markDownToHtml(content, ['br']),
              }}
            />
          </p>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => {
              window.localStorage.setItem(name, 'false');
              setOpen(false);
            }}
            type="button"
            className="inline-flex p-1 me-3 flex-shrink-0"
          >
            <XCircleIcon
              className="h-5 w-5 text-yellow-700 hover:text-yellow-600"
              aria-hidden="true"
            />
            <span className="sr-only">Close banner</span>
          </button>
        </div>
      </div>
    </Transition.Root>
  );
};
