import bankAmp from 'assets/bank/amp.png';
import bankAnz from 'assets/bank/anz.svg';
import bankAustralia from 'assets/bank/bank-australia.png';
import bankGreatSouthern from 'assets/bank/bank-great-southern.svg';
import bankVic from 'assets/bank/bank-vic.svg';
import bankBendigo from 'assets/bank/bbl.svg';
import bankOfMelbourne from 'assets/bank/bom.png';
import bankOfQueensland from 'assets/bank/boq.svg';
import bankWest from 'assets/bank/bwa.svg';
import bankCba from 'assets/bank/cba.svg';
import bankGeneric from 'assets/bank/generic.svg';
import bankHsb from 'assets/bank/hsb.svg';
import bankImb from 'assets/bank/imb.png';
import bankIng from 'assets/bank/ing.png';
import bankMacquarie from 'assets/bank/mbl.svg';
import bankMe from 'assets/bank/meb.png';
import bankUnity from 'assets/bank/mmp.jpeg';
import bankNab from 'assets/bank/nab.png';
import bankNewCastlePermanent from 'assets/bank/new-castle-permanent.png';
import bankStGeorge from 'assets/bank/st-george.webp';
import bankSuncorp from 'assets/bank/sun.png';
import bankYou from 'assets/bank/ubank.svg';
import bankUp from 'assets/bank/up.gif';
import bankWestpac from 'assets/bank/westpac.svg';
import cardAmex from 'assets/card/amex.svg';
import cardDiscover from 'assets/card/discover.svg';
import cardGeneric from 'assets/card/generic.svg';
import cardMastercard from 'assets/card/mastercard.png';
import cardVisa from 'assets/card/visa.svg';
import {PaymentMethodResponse} from 'payble-api-client/schemas';

export const getImageAndAltText = (
  paymentMethod: Omit<PaymentMethodResponse, 'type'>
) => {
  let image = cardGeneric;
  let altText: string | undefined;

  if (paymentMethod.card) {
    const cardType = paymentMethod.card.brand.toLowerCase();
    switch (cardType) {
      case 'vc':
      case 'visa':
        image = cardVisa;
        break;
      case 'mc':
      case 'mastercard':
        image = cardMastercard;
        break;
      case 'discover':
        image = cardDiscover;
        break;
      case 'ax':
      case 'amex':
      case 'american-express':
      case 'americanexpress':
        image = cardAmex;
        break;
      default:
        image = cardGeneric;
        console.warn(`Could not get logo for card type: '${cardType}'`);
        break;
    }
  } else if (paymentMethod.nzBank) {
    image = bankGeneric;
  } else if (paymentMethod.bank) {
    image = bankGeneric;
    altText = paymentMethod.bank.name ?? 'Unknown';
    switch (altText) {
      case 'CBA':
        image = bankCba;
        break;
      case 'ANZ':
        image = bankAnz;
        break;
      case 'NAB':
        image = bankNab;
        break;
      case 'Westpac':
        image = bankWestpac;
        break;
      case 'SGP':
        image = bankStGeorge;
        break;
      case 'MBL':
        image = bankMacquarie;
        break;
      case 'BBL':
        image = bankBendigo;
        break;
      case 'SUN':
      case 'MET':
        image = bankSuncorp;
        break;
      case 'BOM':
        image = bankOfMelbourne;
        break;
      case 'ING':
        image = bankIng;
        break;
      case 'UP':
        image = bankUp;
        break;
      case 'BQL':
      case 'HOM':
        image = bankOfQueensland;
        break;
      case 'BWA':
        image = bankWest;
        break;
      case 'AMP':
        image = bankAmp;
        break;
      case 'MEB':
        image = bankMe;
        break;
      case 'IMB':
      case 'AUB':
        image = bankImb;
        break;
      case 'HSB':
      case 'HBA':
        image = bankHsb;
        break;
      case 'NEW':
        image = bankNewCastlePermanent;
        break;
      case 'MMP':
        image = bankUnity;
        break;
      case 'YOU':
        image = bankYou;
        break;
      case 'BAU':
        image = bankAustralia;
        break;
      case 'CUA':
        image = bankGreatSouthern;
        break;
      case 'VIC':
        image = bankVic;
        break;
    }
  }
  return {image, altText};
};
