import React, {useEffect} from 'react';
import {getWelcomeDebbieContent} from 'features/setup/helpers';
import {useGetAccountTypeInfo} from '../hooks/useGetAccountTypeInfo';
import {useGetAccountFromSearchParams} from '../../../lib/account/useGetAccountFromSearchParams';
import {Debbie} from 'components/organisms/Debbie';
import {getBillerSlugFromUrl} from 'lib/url';
import {LookupAccount} from '../components/LookupAccount';
import {ConfirmLinkAccount} from '../components/ConfirmLinkAccount';
import {Loading} from 'components/atoms/Loading';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useLinkAccount} from '../hooks/useLinkAccount';
import {useInfringementNavigate} from 'features/infringement/hooks/useInfringementNavigate';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {AccountWarningModal} from '../components/AccountWarningModal';
import {useUser} from 'lib/auth/useUser';

const SUPPORTS_PAYMENT_CATEGORY = 'notice';

export const WelcomePlanRequest = () => {
  const billerSlug = getBillerSlugFromUrl();
  const accountTypeInfo = useGetAccountTypeInfo();
  const user = useUser();
  const {
    loading: loadingAccount,
    data: account,
    error,
    requiresVerification,
  } = useGetAccountFromSearchParams();
  const {lookingUpAnother, verificationCode, accountType, patch} =
    useSetupSearchParams();
  const navigate = useSetupNavigate();
  const infringementNavigate = useInfringementNavigate();

  const billerConfig = useBillerConfig();

  useEffect(() => {
    if (accountType === 'infringements') {
      infringementNavigate('/confirm-payment', undefined, undefined, true);
    }
  }, [accountType]);

  const {loading: linkingAccount, linkAccount} = useLinkAccount();
  const loading = loadingAccount || linkingAccount;

  if (!user.biller) {
    return <div>No biller</div>;
  }

  const {title, message} = getWelcomeDebbieContent({
    biller: user.biller,
    includeGetStarted: !loading && !account,
    contact: user.contact,
    account,
    externalIdLabel: accountTypeInfo.externalIdLabel,
    canPayZeroBalanceAccount: billerConfig.canPayZeroBalanceAccount,
  });

  const showLookupForm = lookingUpAnother || (!loading && !account);

  const showConfirmLinking = !lookingUpAnother && !loading && account;

  const onLinkAccount = async () => {
    try {
      await linkAccount({
        accountExternalId: account.externalId,
        accountType: account.type,
        billerSlug,
        verificationCode,
      });
    } catch (e) {
      navigate('/biller/:slug');
      throw e;
    }

    navigate('/biller/:slug/setup/request-plan', {
      verificationCode,
    });
  };

  return (
    <>
      {showLookupForm ? (
        <>
          <Debbie title={title} message={message} />
          <LookupAccount
            loading={loading}
            error={error}
            requiresVerification={requiresVerification}
            onSubmit={values => {
              patch({...values, lookingUpAnother: ''});
            }}
            supportedPaymentCategory={SUPPORTS_PAYMENT_CATEGORY}
          />
        </>
      ) : null}

      {loading ? <Loading /> : null}

      {showConfirmLinking ? (
        <>
          <Debbie title="Is this the property you were looking for?" />
          <AccountWarningModal onConfirm={onLinkAccount} />
          <ConfirmLinkAccount
            account={account}
            onLinkAccount={onLinkAccount}
            onLookupAnotherAccount={() => {
              patch({lookingUpAnother: '1'});
            }}
          />
        </>
      ) : null}
    </>
  );
};
