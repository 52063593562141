import * as React from 'react';
import {cn, cva} from '../lib/utils';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'default' | '4xl' | '2xl' | 'sm';
}

const inputVariants = cva(
  'caret-primary flex h-12 w-full accent-primary-500 shadow-sm rounded-md border border-input px-3 py-2 text-md ring-offset-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:border-transparent disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-foreground-300',
  {
    variants: {
      size: {
        default: 'font-medium',
        sm: 'text-sm h-9 px-2 py-1',
        '2xl':
          'text-2xl font-bold border-0 text-foreground shadow-none text-center focus-visible:ring-0',
        '4xl':
          'text-4xl font-bold border-0 text-foreground shadow-none text-center focus-visible:ring-0',
      },
      focused: {
        true: 'ring-2 ring-ring border-transparent',
        false: '',
      },
      readOnly: {
        true: '',
        false: '',
      },
      type: {
        checkbox: 'border-primary cursor-pointer h-6 w-6',
        default: 'text-foreground-400',
      },
    },
    defaultVariants: {
      size: 'default',
      focused: false,
      readOnly: false,
    },
    compoundVariants: [
      {
        type: 'default',
        readOnly: true,
        className: 'bg-transparent',
      },
      {
        type: 'default',
        readOnly: false,
        className: 'bg-background',
      },
      {
        type: 'checkbox',
        size: 'sm',
        className: 'h-5 w-5',
      },
    ],
  }
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({className, size, type, ...props}, ref) => {
    return (
      <input
        type={type}
        className={cn(
          inputVariants({
            size,
            className,
            type: type === 'checkbox' ? 'checkbox' : 'default',
          })
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export {Input, inputVariants};
