import * as React from 'react';
import {OTPInput, OTPInputContext, OTPInputProps} from 'input-otp';
import {cn} from '../lib/utils';
import {FC, useEffect, useRef} from 'react';

const InputOTPContainer = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput>
>(({className, containerClassName, ...props}, ref) => (
  <OTPInput
    ref={ref}
    containerClassName={cn(
      'flex items-center gap-2 has-[:disabled]:opacity-50',
      containerClassName
    )}
    className={cn('disabled:cursor-not-allowed', className)}
    {...props}
  />
));
InputOTPContainer.displayName = 'InputOTPContainer';

const InputOTPSlot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & {index: number}
>(({index, className, ...props}, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext);
  const {char, hasFakeCaret, isActive} = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex h-12 w-full items-center justify-center border border-input text-sm transition-all rounded-md shadow-sm bg-background',
        isActive && 'z-10 ring-2 ring-ring ring-offset-background',
        className
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
          <div className="w-px h-4 duration-1000 animate-caret-blink bg-foreground" />
        </div>
      )}
    </div>
  );
});
InputOTPSlot.displayName = 'InputOTPSlot';

export type InputOTPProps = Partial<OTPInputProps>;

const InputOTP: FC<InputOTPProps> = ({
  onChange,
  onComplete,
  value,
  disabled,
}) => {
  const ref = useRef<React.ElementRef<typeof OTPInput>>(null);

  useEffect(() => {
    if (!value && !disabled) {
      ref.current?.focus();
    }
  }, [ref, value, disabled]);

  return (
    <InputOTPContainer
      onChange={onChange}
      onComplete={onComplete}
      value={value}
      aria-label="Please enter verification code"
      textAlign="center"
      inputMode="numeric"
      className="!opacity-0"
      maxLength={6}
      disabled={disabled}
      ref={ref}
    >
      <div className="flex items-center flex-1 gap-2">
        <InputOTPSlot index={0} />
        <InputOTPSlot index={1} />
        <InputOTPSlot index={2} />
        <InputOTPSlot index={3} />
        <InputOTPSlot index={4} />
        <InputOTPSlot index={5} />
      </div>
    </InputOTPContainer>
  );
};
InputOTP.displayName = 'InputOTP';

export {InputOTP, InputOTPContainer, InputOTPSlot};
