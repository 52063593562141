import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormInput} from './FormInput';
import {FormFieldError} from './FormFieldError';
import {FormInputPhone} from './FormInputPhone';
import {HTMLAttributes, useEffect, useState} from 'react';
import {FormInputOTP} from './FormInputOTP';
import {z} from 'zod';
import {FormSubmitButton} from './FormSubmitButton';
import {cva} from '../lib/utils';
import {FormConnect} from './FormConnect';
import {FormErrorMessage} from './FormErrorMessage';
import {FormRadioGroup} from './FormRadioGroup';
import {FormCheckboxGroup} from './FormCheckboxGroup';
import {FormInputNumberFormat} from './FormInputNumberFormat';
import {FormSelect} from './FormSelect';
import {FormField} from './FormField';
import {FormSwitch} from './FormSwitch';
import {FormTextarea} from './FormTextarea';
import {FormFieldList} from './FormFieldList';
import {FormUuid} from './FormUuid';
import {FormInputDate} from './FormInputDate';
import {FormInputMoney} from './FormInputMoney';
import {errs} from 'payble-shared';
import {FormInputNumber} from './FormInputNumber';

const formVariants = cva('flex flex-col gap-5');

export type FormRef<T extends FieldValues> = UseFormReturn<T>;

export type FormProps<T extends FieldValues = FieldValues> = Omit<
  HTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  defaultValues?: UseFormProps<T>['defaultValues'];
  mode?: UseFormProps<T>['mode'];
  reValidateMode?: UseFormProps<T>['reValidateMode'];
  onSubmit?: (data: T, form: UseFormReturn<T>) => void;
  errors?: UseFormProps<T>['errors'];
  children: React.ReactNode;
  disabled?: boolean;
  formRef?: React.MutableRefObject<FormRef<T> | undefined>;
  schema?: z.ZodType<any, z.ZodTypeDef, any>;
  debug?: boolean;
};

export const Form = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  mode,
  reValidateMode,
  onSubmit,
  children,
  errors,
  schema,
  formRef,
  disabled,
  debug,
  ...props
}: FormProps<TFieldValues>) => {
  const methods = useForm({
    defaultValues,
    mode,
    reValidateMode,
    disabled,
    errors,
    shouldUnregister: true,
    resolver: schema ? zodResolver(schema) : undefined,
  });
  const [serverError, setServerError] = useState('');
  const formErrors = methods.formState.errors;

  useEffect(() => {
    const nonFieldErrors = Object.entries(methods.formState.errors)
      .filter(([key]) => !Object.keys(methods.getValues()).includes(key))
      .map(([key, e]) => `${key}: ${e?.message}`);

    if (nonFieldErrors.length) {
      throw new Error(
        `Found errors for fields that weren't found in the form:- ${nonFieldErrors.join(
          ','
        )}`
      );
    }
  }, [formErrors]);

  const submit: SubmitHandler<TFieldValues> = data => onSubmit?.(data, methods);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setServerError('');

    try {
      await methods.handleSubmit(submit)(e);
    } catch (e) {
      console.error(e);
      setServerError(errs.coerceToDomainError(e).message);
    }
  };

  useEffect(() => {
    if (formRef) {
      formRef.current = methods;
    }
  }, [formRef, methods]);

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        className={formVariants({className: props.className})}
        onSubmit={async e => {
          e.stopPropagation();
          await handleSubmit(e);
        }}
      >
        {children}
      </form>

      {serverError && <FormErrorMessage message={serverError} />}

      {debug && (
        <FormConnect>
          {(values, form) => (
            <div className="flex flex-col gap-4">
              <div>
                Form values:
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div>
              <div>
                Form state:
                <pre>{JSON.stringify(form.formState, null, 2)}</pre>
              </div>
            </div>
          )}
        </FormConnect>
      )}
    </FormProvider>
  );
};

Form.Field = FormField;
Form.Select = FormSelect;
Form.Switch = FormSwitch;
Form.Uuid = FormUuid;
Form.Input = FormInput;
Form.InputDate = FormInputDate;
Form.InputPhone = FormInputPhone;
Form.InputOTP = FormInputOTP;
Form.InputNumberFormat = FormInputNumberFormat;
Form.InputMoney = FormInputMoney;
Form.InputNumber = FormInputNumber;
Form.RadioGroup = FormRadioGroup;
Form.CheckboxGroup = FormCheckboxGroup;
Form.Textarea = FormTextarea;
Form.SubmitButton = FormSubmitButton;
Form.Connect = FormConnect;
Form.FieldError = FormFieldError;
Form.ErrorMessage = FormErrorMessage;
Form.List = FormFieldList;
