import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {XCircleIcon} from '@heroicons/react/20/solid';

import {InstalmentPlan, useGetInstalmentPlanQuery} from 'lib/graphql/API';
import {Params} from 'lib/navigation/routes';

import {Debbie} from 'components/organisms/Debbie';
import {Spinner} from 'components/atoms/Spinner';
import {Instalments} from './components/Instalments';

import cardIcon from 'assets/icons/card.png';
import {useUser} from 'lib/auth/useUser';

export const Confirmation: React.FC = () => {
  const user = useUser();
  const {slug} = useParams<Params<'/biller/:slug/confirmation'>>();

  useEffect(() => {
    document.title = 'Payble - Direct Debit';
  }, []);

  const {data, loading, error} = useGetInstalmentPlanQuery({
    variables: {
      id: '',
    },
  });

  const payNow = () => {};

  if (loading) {
    return (
      <div className="max-w-lg mx-auto px-2 flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-lg mx-auto px-2">
        <div className="px-4 py-5 sm:px-6"></div>
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {`There was an issue loading the payment options for ${slug}`}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const instalmentPlan = data?.instalmentPlan;

  if (!instalmentPlan) {
    return (
      <div className="max-w-lg mx-auto px-2">
        <div className="px-4 py-5 sm:px-6"></div>
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {'No installment plan exists'}
              </h3>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                onClick={payNow}
              >
                Pay now
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col h-full w-full overflow-hidden p-4 max-w-xl m-auto">
      <Debbie
        title="You're all done"
        message="Your payment will be made in accordance with your
                          billing agreement."
      />
      <div>
        <dl className="text-sm font-medium text-gray-500 space-y-6 mt-6">
          <div className="flex items-center justify-between border-t text-gray-900 pt-6">
            <dt className="text-base">Total</dt>
            <dd className="text-base">${20000}</dd>
          </div>
        </dl>

        <dl className="grid grid-cols-2 gap-x-4 text-sm text-gray-600 mt-4 pt-8 border-t border-gray-200">
          <div>
            <dt className="font-medium text-gray-900">Contact Information</dt>
            <dd className="mt-2">
              <address className="not-italic">
                <span className="block">
                  {user.contact?.givenName}
                  {user.contact?.familyName}
                </span>
                <span className="block">{user.contact?.mobile}</span>
                <span className="block">{user.contact?.email}</span>
              </address>
            </dd>
          </div>
          <div>
            <dt className="font-medium text-gray-900">Payment Information</dt>
            <dd className="mt-2 space-y-2 sm:flex sm:space-y-0 sm:space-x-4">
              <div className="flex-none">
                <img src={cardIcon} className="h-6 w-auto" />
                <p className="sr-only">Card</p>
              </div>
              <div className="flex-auto">
                <p className="text-gray-900">Ending with 4242</p>
                <p>Expires 12 / 22</p>
              </div>
            </dd>
          </div>
        </dl>

        {instalmentPlan && (
          <>
            <dl className="text-sm font-medium text-gray-500 space-y-6 mt-6">
              <div className="flex items-center justify-between border-t border-b text-gray-900 pt-6 pb-6">
                <dt className="text-base">
                  {instalmentPlan.instalments.length} Instalments
                </dt>
                <dd className="text-base">
                  $
                  {(
                    (20000 as number) / instalmentPlan.instalments.length
                  ).toFixed(2)}
                </dd>
              </div>
            </dl>
            <Instalments plan={instalmentPlan as InstalmentPlan} />
          </>
        )}
      </div>
    </div>
  );
};
