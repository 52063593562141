import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';
import {instalmentPlanResponse} from '../schemas';

export const getInstalmentPlans = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getInstalmentPlans',
  queryKey: () => ['user', 'instalment-plans'],
  requestSchema: null,
  responseSchema: z.array(instalmentPlanResponse),
});

export const getInstalmentPlan = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getInstalmentPlan',
  queryKey: body => ['instalment-plan', body?.instalmentPlanId],
  requestSchema: z.object({
    instalmentPlanId: z.string(),
  }),
  responseSchema: instalmentPlanResponse.nullable(),
});
