import React from 'react';
import {XMarkIcon} from '@heroicons/react/20/solid';
import {navigate} from 'lib/navigation/routes';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useGetAccountTypeInfo} from '../hooks/useGetAccountTypeInfo';
import {LookupAccountTypeRadio} from './LookupAccountTypeRadio';
import {Form, Formik} from 'formik';
import {LookupAccountSubmitButton} from './LookupAccountSubmitButton';
import {
  LookupAccountExternalIdInput,
  VerifyAccountForm,
} from './LookupAccountExternalIdInput';
import {getBillerSlugFromUrl} from 'lib/url';
import {AccountTypeInfo, getBillerConfig} from 'payble-shared';
import {markDownToHtml} from 'lib/markDownToHtml';
import {useUser} from 'lib/auth/useUser';

type LookupAccountProps = {
  onSubmit: (values: {accountType: string; accountExternalId: string}) => void;
  supportedPaymentCategory: AccountTypeInfo['paymentCategory'];
  loading?: boolean;
  error?: string;
  requiresVerification?: boolean;
};

export const LookupAccount: React.FC<LookupAccountProps> = ({
  loading,
  error,
  requiresVerification,
  onSubmit,
  supportedPaymentCategory,
}) => {
  const user = useUser();
  const accountTypeInfo = useGetAccountTypeInfo();
  const {accountExternalId, accountType} = useSetupSearchParams();
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);

  return (
    <Formik<{
      accountType: string;
      accountExternalId: string;
      verificationCode?: string;
    }>
      initialValues={{
        accountType,
        accountExternalId,
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="col-span-6 mt-4 mb-4 sm:col-span-3">
          <LookupAccountTypeRadio
            supportedPaymentCategory={supportedPaymentCategory}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <LookupAccountExternalIdInput
            accountTypeInfo={accountTypeInfo}
            disabled={!!loading}
          />
        </div>

        {requiresVerification && (
          <div className="col-span-6 sm:col-span-3">
            <VerifyAccountForm />
          </div>
        )}

        <div className="col-span-6 sm:col-span-3">
          {error && (
            <div className="flex items-center pt-2">
              <XMarkIcon className="w-4 h-4 text-red-500" />
              <span className="mt-1 ml-1 text-sm text-gray-400">{error}</span>
            </div>
          )}

          <LookupAccountSubmitButton loading={loading} cta="Lookup Account" />

          {user.instalmentPlans?.length || user.payments?.length ? (
            <button
              onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
              className="inline-flex items-center justify-center w-full mt-6 text-center text-blue-600 transition hover:text-blue-700"
            >
              Go back home
            </button>
          ) : null}
        </div>
        {billerConfig.accountLookupAgreement.map((term, index) => (
          <p
            className="text-gray-600 [&>a]:underline pt-4"
            key={index}
            dangerouslySetInnerHTML={{
              __html: markDownToHtml(term, ['a']),
            }}
          />
        ))}
      </Form>
    </Formik>
  );
};
