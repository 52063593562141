import React from 'react';
import {Input} from './Input';
import {cn, cva} from '../lib/utils';
import {IconResolver, IconResolverIcon} from './IconResolver';

export interface InputAddonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  iconL?: IconResolverIcon;
  iconR?: IconResolverIcon;
  size?: 'default' | 'sm';
}

const inputAddonVariants = cva('', {
  variants: {
    iconL: {
      true: 'pl-10',
      false: '',
    },
    iconR: {
      true: 'pr-10',
      false: '',
    },
  },
  defaultVariants: {
    iconL: false,
    iconR: false,
  },
});

const InputAddon = React.forwardRef<HTMLInputElement, InputAddonProps>(
  ({iconL, iconR, children, ...props}, ref) => {
    return (
      <div className="relative">
        <Input
          {...props}
          ref={ref}
          className={cn(
            inputAddonVariants({
              iconL: !!iconL,
              iconR: !!iconR,
              className: props.className,
            })
          )}
        />
        {iconL && (
          <div className="absolute top-0 left-0 flex items-center h-full pl-3 pointer-events-none text-icon">
            <IconResolver icon={iconL} />
          </div>
        )}
        {iconR && (
          <div className="absolute top-0 right-0 flex items-center h-full pr-3 pointer-events-none text-icon">
            <IconResolver icon={iconR} />
          </div>
        )}
        {children}
      </div>
    );
  }
);

InputAddon.displayName = 'InputAddon';

export {InputAddon};
