import * as Sentry from '@sentry/react';
import {
  Account,
  AddContactToAccountDocument,
  AddContactToAccountMutation,
  AddContactToAccountMutationVariables,
  Contact,
  CreateInstalmentPlanDocument,
  CreateInstalmentPlanMutation,
  CreateInstalmentPlanMutationVariables,
  CreatePlanRequestDocument,
  CreatePlanRequestMutation,
  CreatePlanRequestMutationVariables,
  GetAuthKeyDocument,
  GetAuthKeyQuery,
  InstalmentFrequency,
  InstalmentPlanMode,
  UpdateContactDocument,
  UpdateContactMutation,
  UpdateContactMutationVariables,
} from 'lib/graphql/API';
import {client} from 'lib/graphql/client';
import {AbsoluteDate, errs, Result} from 'payble-shared';

export async function addContactToAccount(
  accountReference: string,
  accountType: string,
  verificationCode?: string
): Promise<Result<Account>> {
  try {
    const result = await client.mutate<
      AddContactToAccountMutation,
      AddContactToAccountMutationVariables
    >({
      mutation: AddContactToAccountDocument,
      variables: {
        input: {
          accountReference,
          type: accountType,
          verificationCode,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      const errors = errs.fromGraphQL({graphQLErrors: result.errors});
      return errors.find(errs.AccountVerificationError) ?? errors.first();
    }

    return result?.data?.addContactToAccount as Account;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return errs.UnexpectedError.wrap(error, 'Network error');
  }
}

export async function updateContact(
  input: UpdateContactMutationVariables['input']
): Promise<Result<Contact>> {
  try {
    client.cache.gc();

    const result = await client.mutate<
      UpdateContactMutation,
      UpdateContactMutationVariables
    >({
      mutation: UpdateContactDocument,
      variables: {
        input,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    const contact = result?.data?.updateContact as Contact;
    return contact;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

export type createInstalmentPlanOptions = {
  accountId: string;
  paymentMethodId: string;
  frequency: InstalmentFrequency;
  startAt?: AbsoluteDate;
  payMode: InstalmentPlanMode;
  targetDate?: AbsoluteDate;
  amount?: number;
  offPeriodInstalmentAmount?: number;
};

export type CreatedInstalmentPlan = {
  instalmentPlanId: string;
  frequency?: string;
  nextInstalmentDueAt?: AbsoluteDate;
  mode: InstalmentPlanMode;
  instalments: {
    dueAt: AbsoluteDate;
    amount: number;
  }[];
};
export async function createInstalmentPlan({
  accountId,
  paymentMethodId,
  frequency,
  startAt,
  payMode = InstalmentPlanMode.PayEveryX,
  targetDate,
  amount,
  offPeriodInstalmentAmount,
}: createInstalmentPlanOptions): Promise<Result<CreatedInstalmentPlan>> {
  try {
    const result = await client.mutate<
      CreateInstalmentPlanMutation,
      CreateInstalmentPlanMutationVariables
    >({
      mutation: CreateInstalmentPlanDocument,
      variables: {
        input: {
          accountId,
          paymentMethodId,
          offPeriodInstalmentAmount,
          frequency,
          payMode,
          startAt,
          targetDate,
          amount,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return {
      mode: result.data?.createInstalmentPlan?.mode!,
      instalmentPlanId: result?.data?.createInstalmentPlan?.id as string,
      frequency: result.data?.createInstalmentPlan?.frequency ?? undefined,
      instalments:
        result.data?.createInstalmentPlan?.instalments.map(instalment => ({
          amount: instalment.amount,
          dueAt:
            typeof instalment.dueAt === 'string'
              ? AbsoluteDate.fromISO(instalment.dueAt as string)
              : instalment.dueAt,
        })) ?? [],
      nextInstalmentDueAt:
        (typeof result?.data?.createInstalmentPlan?.nextInstalmentDueAt ===
        'string'
          ? AbsoluteDate.fromISO(
              result?.data?.createInstalmentPlan?.nextInstalmentDueAt as string
            )
          : result?.data?.createInstalmentPlan?.nextInstalmentDueAt) ??
        undefined,
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

type GetAuthKeyResult = {
  authKey: string;
  origin: string;
  tokenExId: string;
  environment: 'test' | 'production';
};

export async function getAuthKey(): Promise<Result<GetAuthKeyResult>> {
  try {
    const result = await client.query<GetAuthKeyQuery>({
      query: GetAuthKeyDocument,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    const authKey = result.data.authKey;
    if (!authKey) {
      return new Error('Could not get an authKey from server');
    }
    return {
      ...authKey,
      environment: authKey.environment as 'test' | 'production',
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

export type CreatePlanRequest = {
  accountId: string;
  paymentMethodId: string;
  frequency: InstalmentFrequency;
  startAt: AbsoluteDate;
  instalmentAmount: number;
  contactId: string;
};

export type CreatedPlanRequest = {
  instalmentAmount: number;
  frequency: string;
  startAt: AbsoluteDate;
};

export async function createPlanRequest({
  accountId,
  paymentMethodId,
  frequency,
  startAt,
  instalmentAmount,
  contactId,
}: CreatePlanRequest): Promise<Result<CreatedPlanRequest>> {
  try {
    const result = await client.mutate<
      CreatePlanRequestMutation,
      CreatePlanRequestMutationVariables
    >({
      mutation: CreatePlanRequestDocument,
      variables: {
        input: {
          accountId,
          paymentMethodId,
          frequency,
          startAt,
          instalmentAmount,
          contactId,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result?.errors[0]?.message ?? 'Unknown error');
    }

    if (!result?.data?.createPlanRequest) {
      return new Error('No create plan request returned');
    }

    return {
      frequency: result?.data?.createPlanRequest?.frequency!,
      instalmentAmount: result?.data?.createPlanRequest?.instalmentAmount!,
      startAt:
        typeof result?.data?.createPlanRequest?.startAt! === 'string'
          ? AbsoluteDate.fromISO(
              result?.data?.createPlanRequest?.startAt! as string
            )
          : result?.data?.createPlanRequest?.startAt!,
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}
