import React from 'react';
import {DateTime} from 'luxon';
import NumberFormat from 'react-number-format';
import {classNames} from 'lib/styles';
import {formatToDollars, toDateTime, toTitleCase} from 'payble-shared';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {InstalmentPlanResponse} from 'payble-api-client/schemas';

type InstalmentTableProps = {
  instalmentPlan: InstalmentPlanResponse;
};

export const InstalmentTable: React.FC<InstalmentTableProps> = ({
  instalmentPlan,
}) => {
  const billerConfig = useBillerConfig();
  const rows = instalmentPlan.instalments.map(x => {
    let actionDate: DateTime | null = null;
    switch (x.status) {
      case 'skipped':
        actionDate = x.skippedAt
          ? toDateTime(x.skippedAt, {billerConfig})
          : null;
        break;
      case 'cancelled':
        actionDate = x.cancelledAt
          ? toDateTime(x.cancelledAt, {billerConfig})
          : null;
        break;
      case 'paid':
        actionDate = x.paidAt ? toDateTime(x.paidAt, {billerConfig}) : null;
        break;
      case 'missed':
        actionDate = x.missedAt ? toDateTime(x.missedAt, {billerConfig}) : null;
        break;
    }

    return (
      <div key={x.instalmentId} className="flex py-2 text-xs sm:text-sm">
        <div className="px-2 basis-3/12">{x.dueAt.toFormat('dd MMM yyyy')}</div>
        <div className="px-2 text-right basis-2/12">
          <NumberFormat
            value={formatToDollars(x.amount)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />
        </div>
        <div className="justify-center px-2 pl-6 text-center basis-4/12">
          <div
            className={classNames(
              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs sm:text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
              x.status === 'paid' ? 'bg-green-100 text-green-800' : '',
              x.status === 'scheduled' ? 'bg-gray-100 text-gray-800' : '',
              x.status === 'processing' ? 'bg-gray-100 text-gray-800' : '',
              x.status === 'missed' ? 'bg-red-100 text-red-800' : '',
              x.status === 'skipped' ? 'bg-orange-100 text-orange-800' : '',
              x.status === 'cancelled' ? 'bg-yellow-100 text-yellow-800' : ''
            )}
          >
            {toTitleCase(x.status as string)}
          </div>
        </div>
        <div className="px-2 text-right basis-3/12">
          {actionDate !== null ? actionDate.toFormat('dd MMM yyyy') : ''}
        </div>
      </div>
    );
  });

  return (
    <div className="mt-4 divide-y divide-gray-50">
      <div className="flex py-2 text-xs font-semibold sm:text-sm">
        <div className="px-2 basis-3/12">Due Date</div>
        <div className="px-2 text-right basis-2/12">Amount</div>
        <div className="px-2 pl-6 text-center basis-4/12">Status</div>
        <div className="px-2 text-right basis-3/12">Actioned</div>
      </div>
      {rows}
    </div>
  );
};
