import React from 'react';
import {PaymentMethodRow} from 'features/instalment-plan/components/PaymentMethodRow';
import {PaymentMethodResponse} from 'payble-api-client/schemas';

type ChoosePaymentMethodProps = {
  paymentMethods: PaymentMethodResponse[];
  selectedPaymentMethodId?: string;
  onChange: (paymentMethodId: string) => void;
};

export const ChoosePaymentMethod: React.FC<ChoosePaymentMethodProps> = ({
  paymentMethods,
  selectedPaymentMethodId,
  onChange,
}) => {
  const paymentMethodList = paymentMethods
    .filter(x => x.status !== 'removed')
    .map(x => {
      const selected = selectedPaymentMethodId === x.id;

      return (
        <div key={x.id} className="relative flex items-start py-4">
          <PaymentMethodRow paymentMethod={x} />
          <div className="ml-3 flex items-center place-self-center">
            <input
              id={`account-${x.id}`}
              aria-describedby={`account-${x.id}-description`}
              name="account"
              type="radio"
              defaultChecked={selected}
              onClick={() => onChange(x.id)}
              onChange={() => onChange(x.id)}
              className="focus:ring-b;ie-500 h-4 w-4 text-blue-600 border-gray-300"
              disabled={x.status !== 'active'}
            />
          </div>
        </div>
      );
    });

  return <div className="divide-y divide-gray-200">{paymentMethodList}</div>;
};
