import {FC, InputHTMLAttributes, ReactNode} from 'react';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import NumberFormat, {NumberFormatProps} from 'react-number-format';
import {Controller} from 'react-hook-form';
import {InputProps, inputVariants} from './Input';
import {cn} from '../lib/utils';
import {formatToCents, formatToDollars} from 'payble-shared';

export interface FormInputMoneyProps
  extends Omit<
    NumberFormatProps<InputHTMLAttributes<HTMLInputElement>>,
    'size'
  > {
  label?: ReactNode;
  name: string;
  size?: InputProps['size'];
  allowNegative?: boolean;
  setValueAs?: 'cents';
}

export const FormInputMoney: FC<FormInputMoneyProps> = ({
  label,
  name,
  disabled,
  size,
  allowNegative = false,
  setValueAs = 'cents',
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <Controller
        control={form.control}
        name={name}
        disabled={disabled}
        render={({formState, field: {onChange, value, disabled}}) => {
          return (
            <NumberFormat
              id={id}
              prefix={'$'}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              data-testid="moneyInput"
              allowLeadingZeros={false}
              {...props}
              allowNegative={allowNegative}
              value={formatToDollars(parseFloat(value))}
              disabled={disabled || formState.isSubmitting}
              placeholder={props.placeholder}
              onValueChange={async values => {
                if (values.value === '') {
                  onChange('');
                  return;
                }

                const floatValue = values.floatValue;

                onChange(
                  floatValue !== undefined && setValueAs === 'cents'
                    ? formatToCents(floatValue)
                    : floatValue
                );
              }}
              spellCheck={false}
              autoComplete="off"
              className={cn(
                inputVariants({
                  size,
                  readOnly: !!props.readOnly,
                  className: props.className,
                })
              )}
            />
          );
        }}
      />
    </FormField>
  );
};
