import {z} from 'zod';
import {zAbsoluteDate} from '../schemas';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';
import {AccountInstalmentRangeResponse} from '../schemas/account';

export const accountInstalmentRange = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /account/instalment-range',
  requestSchema: z.object({
    accountId: z.string().uuid(),
    startDate: zAbsoluteDate,
  }),
  queryKey: e => ['account', 'instalment-range', e?.accountId, e?.startDate],
  responseSchema: AccountInstalmentRangeResponse,
});
