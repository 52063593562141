import {cn} from '../lib/utils';
import {FC, ReactNode, forwardRef} from 'react';
import {Input} from './Input';

export interface CheckboxGroupProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  size?: 'sm' | 'default';
  options?: {
    value: string;
    label: ReactNode;
    description?: ReactNode;
    disabled?: boolean;
  }[];
  defaultValue?: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
}

const CheckboxGroup: FC<CheckboxGroupProps> = forwardRef<
  HTMLInputElement,
  CheckboxGroupProps
>(({id, className, options, disabled, onChange, value, ...props}, ref) => {
  const handleCheckboxChange = (checkValue: string) => {
    if (value?.includes(checkValue)) {
      onChange?.(value?.filter(v => v !== checkValue));
    } else {
      onChange?.([...(value || []), checkValue]);
    }
  };

  return (
    <div className={cn('flex flex-col gap-2', className)} ref={ref}>
      {options?.map(option => (
        <label
          htmlFor={`${option.value}-${id}`}
          className={cn('flex gap-3 cursor-pointer items-center')}
          key={`${option.value}-${id}`}
        >
          <Input
            {...props}
            id={`${option.value}-${id}`}
            type="checkbox"
            name={option.value}
            disabled={option.disabled ?? disabled}
            onChange={() => handleCheckboxChange(option.value)}
            checked={!!value?.includes(option.value)}
          />
          <div className="flex flex-col flex-1 gap-2">
            <span className="text-sm text-foreground">{option.label}</span>
            {option.description && (
              <span className="text-xs text-muted">{option.description}</span>
            )}
          </div>
        </label>
      ))}
    </div>
  );
});

export {CheckboxGroup};
