import React from 'react';

type AlternativePlansProps = {
  onClick: () => void;
};

export const AlternativePlans: React.FC<AlternativePlansProps> = ({
  onClick,
}) => {
  return (
    <div className="py-2">
      <p className="py-2 text-sm text-center text-gray-700 cursor-pointer">
        If you aren’t able to make the payments above, Council may allow you to
        request an alternative payment plan
      </p>
      <p className="text-sm text-center text-blue-400 underline cursor-pointer">
        <a onClick={onClick} id="alt-plans">
          View payment plan options
        </a>
      </p>
    </div>
  );
};
