import React, {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {goBack} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';
import {Loading} from 'components/atoms/Loading';
import {InstalmentPlanPreview} from 'features/setup/shared/InstalmentPlanPreview';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {buttonClasses} from 'lib/styles';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {
  InstalmentFrequency,
  Tag,
  usePreviewInstalmentPlanQuery,
} from 'lib/graphql/API';
import {useSetupRoute} from '../components/SetupRoute';
import {useSetupNavigate} from '../hooks/useSetupNavigate';

function isPlanConsideredArrearsRecovery(tags: Tag[]) {
  return !!tags.find(tag => tag.internalKey === 'arrears_recovery');
}

type ErrorDisplayProps = {
  errorMessage?: string;
};

const ErrorDisplay = ({errorMessage}: ErrorDisplayProps) => {
  return (
    <div>
      <ErrorMessage message={errorMessage ?? 'Something went wrong'} />
      <button type="button" onClick={goBack} className={buttonClasses}>
        Go back
      </button>
    </div>
  );
};

export const PlanPreview = ({
  onConfirm: parentOnConfirm,
  onBack: parentOnBack,
  shouldShowZeroAmounts = true,
}: {
  onConfirm?: () => void;
  onBack?: () => void;
  shouldShowZeroAmounts?: boolean;
}) => {
  const billerSlug = getBillerSlugFromUrl();
  const {account} = useSetupRoute();

  const {
    mode,
    accountType,
    instalmentMode,
    accountExternalId,
    instalmentFrequency,
    instalmentStartAt,
    amountInCents,
    instalmentTargetDate,
    nextNotice,
  } = useSetupSearchParams();
  const navigate = useSetupNavigate();

  const {data, error, loading} = usePreviewInstalmentPlanQuery({
    variables: {
      input: {
        payMode: instalmentMode,
        accountId: account.id,
        frequency: instalmentFrequency ?? InstalmentFrequency.Weekly,
        startAt: instalmentStartAt,
        targetDate: instalmentTargetDate,
        amount: account.amountOwing ?? 0,
        ...(instalmentMode === 'SmoothPay'
          ? {offPeriodInstalmentAmount: amountInCents}
          : {}),
      },
    },
    onError: error => {
      Sentry.captureException(
        new Error(
          `Could not load instalment preview: ${
            accountExternalId ?? 'external id missing'
          } biller: ${billerSlug ?? 'slug missing'} ${error.message}`
        )
      );
    },
  });

  const {balancedInstalmentPreview, tags} =
    data?.previewInstalmentPlan[0] || {};

  useEffect(() => {
    if (
      !loading &&
      balancedInstalmentPreview !== undefined &&
      balancedInstalmentPreview?.length === 0
    ) {
      navigate(
        '/biller/:slug/setup/payment-method',
        undefined,
        undefined,
        true
      );
    }
  }, [balancedInstalmentPreview]);

  if (loading || !tags) return <Loading />;

  const onConfirm = () => {
    if (mode === 'PAY_SMOOTH' && !amountInCents && nextNotice === 'regular') {
      navigate('/biller/:slug/setup/smooth/amount');
    } else if (isPlanConsideredArrearsRecovery(tags)) {
      navigate('/biller/:slug/setup/flexible/recovery-terms');
    } else {
      navigate('/biller/:slug/setup/payment-method');
    }
  };

  if (error) {
    return <ErrorDisplay errorMessage={error.message} />;
  }

  if (data?.previewInstalmentPlan.length !== 1) {
    Sentry.captureException(
      new Error('got unexpected number of instalment plan previews')
    );
    return <ErrorDisplay errorMessage="Something went wrong" />;
  }

  const groups = (balancedInstalmentPreview ?? []).filter(group =>
    shouldShowZeroAmounts ? true : group.amount !== 0
  );

  const shouldDisplayGroups =
    mode === 'PAY_BALANCED' ||
    (mode === 'PAY_SMOOTH' && groups[0].instalments.length > 1);

  const shouldDisplayNotice = accountType !== 'infringements';

  return (
    <InstalmentPlanPreview
      shouldDisplayNotice={shouldDisplayNotice}
      shouldDisplayGroups={shouldDisplayGroups}
      balancedGroups={groups}
      onConfirm={parentOnConfirm ?? onConfirm}
      onBack={parentOnBack ?? goBack}
    />
  );
};
