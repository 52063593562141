import {FC, ReactNode} from 'react';
import {useRegisterField} from '../hooks/useRegisterField';
import {CheckboxGroup, CheckboxGroupProps} from './CheckboxGroup';
import {FormField} from './FormField';
import {Controller} from 'react-hook-form';

export interface FormCheckboxGroupProps extends CheckboxGroupProps {
  label?: ReactNode;
  name: string;
}

export const FormCheckboxGroup: FC<FormCheckboxGroupProps> = ({
  label,
  name,
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField
      id={id}
      name={name}
      label={label}
      className="flex-1"
      size={props.size}
    >
      <Controller
        control={form.control}
        name={name}
        render={({formState, field: {onChange, ...f}}) => (
          <CheckboxGroup
            {...props}
            onChange={onChange}
            {...f}
            disabled={props.disabled || f.disabled || formState.isSubmitting}
          />
        )}
      />
    </FormField>
  );
};

FormCheckboxGroup.displayName = 'FormCheckboxGroup';
