import {FC, ReactNode} from 'react';
import {InputAddonProps} from './InputAddon';
import {Controller} from 'react-hook-form';
import {InputOTPProps} from './InputOTP';
import {InputOTP} from './InputOTP';
import {useRegisterField} from '../hooks/useRegisterField';
import {FormField} from './FormField';

export type FormInputOTPProps = InputAddonProps &
  InputOTPProps & {
    label?: ReactNode;
    name: string;
  };

export const FormInputOTP: FC<FormInputOTPProps> = ({
  label,
  name,
  disabled,
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <Controller
        control={form.control}
        name={name}
        disabled={disabled}
        render={({formState, field: {onChange, onBlur, value, disabled}}) => {
          return (
            <InputOTP
              id={id}
              disabled={disabled || formState.isSubmitting}
              value={value}
              onChange={v => {
                onChange(v);
                props.onChange?.(v);
              }}
              onComplete={v => {
                onChange(v);
                onBlur();
                props.onComplete?.(v, form);
              }}
            />
          );
        }}
      />
    </FormField>
  );
};
