import React from 'react';
import NumberFormat from 'react-number-format';

import {getImageAndAltText} from 'lib/logos';
import {formatLast4} from 'payble-shared';
import {PaymentMethodResponse} from 'payble-api-client/schemas';

type PaymentMethodRowProps = {
  paymentMethod: PaymentMethodResponse;
};

export const PaymentMethodRow: React.FC<PaymentMethodRowProps> = ({
  paymentMethod,
}) => {
  const {image} = getImageAndAltText(paymentMethod);

  return (
    <div className="flex-1 min-w-0 text-sm">
      {paymentMethod.type === 'card' && paymentMethod.card && (
        <>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            className="font-semibold text-gray-700"
          >
            Credit/Debit Card
          </label>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            id={`account-${paymentMethod.id}-description`}
            className="text-gray-500"
          >
            <div className="flex">
              <img className="flex-none object-contain h-14 w-14" src={image} />
              <div className="flex-1 mt-2 ml-4">
                <div className="font-mono">
                  {formatLast4(paymentMethod.card.last4)}
                </div>
                <div>
                  {paymentMethod.card.expiryMonth} /{' '}
                  {paymentMethod.card.expiryYear}
                </div>
              </div>
            </div>
          </label>
        </>
      )}

      {paymentMethod.type === 'direct_debit' && paymentMethod.bank && (
        <>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            className="font-semibold text-gray-700"
          >
            Direct Debit{' - '}
            <span className="text-gray-500">({paymentMethod.bank.name})</span>
          </label>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            id={`account-${paymentMethod.id}-description`}
            className="text-gray-500"
          >
            <div className="flex">
              <div
                className="flex-none h-10 my-2 bg-center bg-no-repeat bg-contain w-14"
                style={{backgroundImage: `url(${image})`}}
              ></div>

              <div className="flex-1 mt-2 ml-4">
                <div className="font-mono">
                  <NumberFormat
                    value={paymentMethod.bank.bsb}
                    displayType={'text'}
                    format="###-###"
                  />
                </div>
                <div>{paymentMethod.bank.accountNumber}</div>
              </div>
            </div>
          </label>
        </>
      )}

      {paymentMethod.type === 'nz_direct_debit' && paymentMethod.nzBank && (
        <>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            className="font-semibold text-gray-700"
          >
            Direct Debit{' - '}
            <span className="text-gray-500">({paymentMethod.nzBank.name})</span>
          </label>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            id={`account-${paymentMethod.id}-description`}
            className="text-gray-500"
          >
            <div className="flex">
              <div
                className="flex-none h-10 my-2 bg-center bg-no-repeat bg-contain w-14"
                style={{backgroundImage: `url(${image})`}}
              ></div>

              <div className="flex-1 mt-2 ml-4">
                <div className="font-mono">
                  {paymentMethod.nzBank.accountName}
                </div>
                <div>{paymentMethod.nzBank.accountNumber.toJSON()}</div>
              </div>
            </div>
          </label>
        </>
      )}

      {paymentMethod.status === 'disabled' && (
        <div className="italic text-red-600">
          Payment method disabled due to "{paymentMethod.reason}"
        </div>
      )}
    </div>
  );
};
