import {z} from 'zod';
import {contactResponse, paymentMethodResponse} from './contact';
import {instalmentPlanResponse} from './plan';
import {zISODate} from './generic';
import {accountResponse} from './account';

export const addPaymentMethodResponse = z.object({
  contact: contactResponse,
  paymentMethodId: z.string(),
  updatedPlans: z.array(instalmentPlanResponse),
  paymentMethod: paymentMethodResponse,
});

export type AddPaymentMethodResponse = z.infer<typeof addPaymentMethodResponse>;

export const paymentStatusSchema = z.enum([
  'processing',
  'cancelled',
  'succeeded',
  'failed',
]);

export const paymentModeSchema = z.enum([
  'pay-instalment',
  'pay-instalment-plan-remainder',
  'pay-in-full',
  'pay-amount',
]);

export const paymentResponse = z.object({
  id: z.string(),
  mode: paymentModeSchema,
  currency: z.string(),
  amount: z.number(),
  accountId: z.string(),
  account: accountResponse.optional(),
  instalmentId: z.string().nullable(),
  instalmentPlanId: z.string().nullable(),
  paymentMethodId: z.string(),
  testMode: z.boolean(),
  status: paymentStatusSchema,
  refundStatus: z
    .enum(['processing', 'cancelled', 'succeeded', 'failed'])
    .nullable(),
  gateway: z.string(),
  gatewayTransactionId: z.string().nullable(),
  processedAt: zISODate.nullable(),
  succeededAt: zISODate.nullable(),
  failedAt: zISODate.nullable(),
  refundedAt: zISODate.nullable(),
  createdAt: zISODate,
});

export type PaymentResponse = z.infer<typeof paymentResponse>;
