import {
  InvalidateQueryFilters,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  keepPreviousData,
  useQueryClient,
} from '@tanstack/react-query';
import {
  APIClient,
  AnyAPISpec,
  HTTPEndpointRequestData,
  HTTPEndpointResponse,
  HTTPQueryKey,
  QueryKeysOf,
} from 'payble-api-client';
import {errs} from 'payble-shared';

type MakeAPIHooks = <S extends AnyAPISpec>(
  spec: S,
  useAPI: () => {api: APIClient<S>}
) => {
  useAPIInvalidate: () => (filters?: InvalidateQueryFilters) => Promise<void>;
  useAPIMutation: APIMutatorHook<S>;
  useAPIQuery: APIQueryHook<S>;
};
type APIMutatorHook<S extends AnyAPISpec> = <OP extends keyof S>(
  operation: OP,
  options?: {
    invalidates?: (
      response: HTTPEndpointResponse<S[OP]>,
      request: HTTPEndpointRequestData<S[OP]>
    ) => Partial<QueryKeysOf<S>>[];
    query?: Omit<
      UseMutationOptions<
        HTTPEndpointResponse<S[OP]>,
        errs.DomainError,
        HTTPEndpointRequestData<S[OP]>,
        unknown
      >,
      'mutationFn'
    >;
  }
) => UseMutationResult<
  HTTPEndpointResponse<S[OP]>,
  errs.DomainError,
  HTTPEndpointRequestData<S[OP]>,
  unknown
>;

type APIQueryHook<S extends AnyAPISpec> = <OP extends keyof S>(
  operation: OP,
  options: {
    data?: HTTPEndpointRequestData<S[OP]>;
    query?: Omit<
      UseQueryOptions<
        HTTPEndpointRequestData<S[OP]>,
        errs.DomainError,
        HTTPEndpointResponse<S[OP]>,
        HTTPQueryKey<S[OP]>
      >,
      'queryKey' | 'queryFn'
    > & {keepPreviousData?: boolean};
  }
) => UseQueryResult<HTTPEndpointResponse<S[OP]>, errs.DomainError>;

export const makeAPIHooks: MakeAPIHooks = (spec, useAPI) => {
  return {
    useAPIInvalidate: () => {
      const queryClient = useQueryClient();

      return (filters?: InvalidateQueryFilters) => {
        return queryClient.invalidateQueries(filters);
      };
    },

    useAPIQuery: (operation, options) => {
      const {api} = useAPI();

      const definition = spec[operation];
      if (!definition) {
        throw new Error(
          `Invalid endpoint definition for ${operation as string}. Not in specification.`
        );
      }
      return useQuery({
        refetchOnWindowFocus: false,
        ...options?.query,
        enabled:
          options?.query?.enabled ?? (options.data == null ? false : true),
        queryKey: definition.queryKey(options.data),
        queryFn: (async ({signal}: {signal: AbortSignal}) => {
          const {data} = options;

          return await api.request(operation, {data, abort: signal}).get();
        }) as any,
        placeholderData: options?.query?.keepPreviousData
          ? keepPreviousData
          : undefined,
      }) as any;
    },

    useAPIMutation: (operation, options) => {
      const {api} = useAPI();
      const queryClient = useQueryClient();

      return useMutation({
        mutationKey: [operation],
        onSuccess: async (...args) => {
          const invalidations = options?.invalidates?.(args[0], args[1]);
          await Promise.all(
            invalidations?.map(queryKey =>
              queryClient.invalidateQueries(queryKey)
            ) ?? []
          );
          await options?.query?.onSuccess?.(...args);
        },
        ...options?.query,
        mutationFn: async data => {
          return await api.request(operation, {data}).get();
        },
      });
    },
  };
};
