import {z} from 'zod';

export const billerResponse = z.object({
  id: z.string(),
  billerId: z.string(),
  name: z.string(),
  slug: z.string(),
});

export type BillerResponse = NonNullable<z.infer<typeof billerResponse>>;
