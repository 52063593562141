import {useAPIQuery} from 'lib/api';
import _refiner from 'refiner-js';
import {useEffect, useMemo} from 'react';
import {loadSessionData} from './initialisation';
import {analytics} from 'src/analytics/hooks/useAnalytics';
import {clear} from './token';
import {navigate} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {getBillerSlugFromUrl} from 'lib/url';

export const useLoadUser = (enabled: boolean) => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();

  const {
    data: contact,
    isPending: isLoadingContact,
    isFetching: isFetchingContact,
    isSuccess: isSuccessContact,
    isError: isContactError,
  } = useAPIQuery('getSessionContact', {
    query: {
      enabled,
      retry: 1,
    },
  });

  const {
    data: biller,
    isPending: isLoadingBiller,
    isFetching: isFetchingBiller,
    isSuccess: isSuccessBiller,
    isError: isBillerError,
  } = useAPIQuery('getSessionBiller', {
    data: {billerSlug},
    query: {
      enabled,
      retry: 1,
    },
  });

  const {
    data: instalmentPlans,
    isPending: isLoadingInstalmentPlans,
    isFetching: isFetchingInstalmentPlans,
    isSuccess: isSuccessInstalmentPlans,
    isError: isInstalmentPlansError,
  } = useAPIQuery('getInstalmentPlans', {
    query: {
      enabled,
      retry: 1,
    },
  });

  const {
    data: payments,
    isPending: isLoadingPayments,
    isFetching: isFetchingPayments,
    isSuccess: isSuccessPayments,
    isError: isPaymentsError,
  } = useAPIQuery('getPayments', {
    query: {
      enabled,
      retry: 1,
    },
  });

  const isLoading =
    isLoadingContact ||
    isLoadingInstalmentPlans ||
    isLoadingBiller ||
    isLoadingPayments;

  const isFetching =
    isFetchingContact ||
    isFetchingInstalmentPlans ||
    isFetchingBiller ||
    isFetchingPayments;

  const isSuccess =
    !isFetching &&
    isSuccessContact &&
    isSuccessInstalmentPlans &&
    isSuccessBiller &&
    isSuccessPayments;

  const isError =
    isPaymentsError ||
    isInstalmentPlansError ||
    isBillerError ||
    isContactError;

  useEffect(() => {
    if (contact && biller) {
      // @ts-expect-error This is used by e2e tests
      window.contactId = contact.id;

      analytics.reidentify();

      _refiner('identifyUser', {
        id: contact?.id,
        email: contact?.email,
        name: `${contact?.givenName} ${contact?.familyName}`,
        biller_slug: biller?.slug,
      });
    }
  }, [contact, biller]);

  useEffect(() => {
    if (isError) {
      clear();
      navigate('/biller/:slug', {slug: billerConfig.billerSlug});
    }
  }, [isError]);

  return useMemo(() => {
    const sessionData = loadSessionData();

    return {
      sessionData,
      contact,
      biller,
      instalmentPlans,
      payments,
      isLoading,
      isFetching,
      isSuccess: isSuccess && !isFetching && !isLoading,
    };
  }, [
    contact,
    biller,
    instalmentPlans,
    payments,
    isLoading,
    isFetching,
    isSuccess,
  ]);
};
