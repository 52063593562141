import {
  GetAccountByExternalIdAnonymouslyQuery,
  useGetAccountByExternalIdAnonymouslyLazyQuery,
} from 'lib/graphql/publicAPI';
import {
  Account,
  GetAccountByExternalIdQuery,
  useGetAccountByExternalIdLazyQuery,
} from 'lib/graphql/API';
import {useEffect} from 'react';
import {errs} from 'payble-shared';
import {ApolloError} from '@apollo/client';
import {getBillerSlugFromUrl} from 'lib/url';
import {useSetupSearchParams} from '../../features/setup/hooks/useSetupSearchParams';

function getError(
  apolloError?: ApolloError,
  data?: GetAccountByExternalIdQuery
) {
  let error;
  let requiresVerification = false;

  if (apolloError) {
    const errors = errs.fromGraphQL(apolloError);
    const err = errors.find(errs.AccountVerificationError) ?? errors.first();
    if (err instanceof errs.AccountVerificationError) {
      requiresVerification = true;
    }
    error = err.message;
  } else if (data?.accountByExternalId === null) {
    error = "Sorry, we couldn't find that account. Please try again.";
  }

  return {error, requiresVerification};
}

function getAccountData(
  data?: GetAccountByExternalIdAnonymouslyQuery | GetAccountByExternalIdQuery,
  anonymous = false
) {
  const accountData = anonymous
    ? (data as GetAccountByExternalIdAnonymouslyQuery)
        ?.accountByExternalIdAnonymously
    : (data as GetAccountByExternalIdQuery)?.accountByExternalId;

  return accountData as Account;
}

function useGetAccount(anonymous = false) {
  const hook = anonymous
    ? useGetAccountByExternalIdAnonymouslyLazyQuery
    : useGetAccountByExternalIdLazyQuery;
  return hook();
}

type GetAccountFromSearchParamsOptions = {anonymous?: boolean};
export function useGetAccountFromSearchParams(
  options?: GetAccountFromSearchParamsOptions
) {
  const billerSlug = getBillerSlugFromUrl();

  const {
    accountType,
    accountExternalId,
    verificationCode,
    patch: patchSearchParams,
  } = useSetupSearchParams();

  const [getAccountByExternalId, {loading, data, error: apolloError, refetch}] =
    useGetAccount(options?.anonymous);

  useEffect(() => {
    if (billerSlug && accountExternalId && accountType) {
      // Hack for Yarra Ranges Council to just get them live 22-01-2025
      const externalId =
        billerSlug === 'yarra-ranges-council'
          ? // worried about old browsers not supporting replaceAll
            accountExternalId.replace(/\//g, '')
          : accountExternalId;

      getAccountByExternalId({
        variables: {
          billerSlug,
          externalId,
          type: accountType,
          verificationCode,
        },
      });
    }
  }, [
    billerSlug,
    accountExternalId,
    accountType,
    verificationCode,
    getAccountByExternalId,
  ]);

  const {error, requiresVerification} = getError(apolloError, data);

  return {
    loading,
    data: getAccountData(data, options?.anonymous),
    error,
    requiresVerification,
    patchSearchParams,
    refetch,
  };
}
