import {InstalmentPlanResponse} from 'payble-api-client/schemas';

export function getInstalmentPlanHeading(
  instalmentPlan: InstalmentPlanResponse
) {
  let heading;

  switch (instalmentPlan.mode) {
    case 'pay-x-on-y':
      heading = 'autopay';
      break;

    case 'pay-on-y':
      heading = 'scheduled one-off payment';
      break;

    default:
      heading = instalmentPlan.frequency
        ? `flexible ${instalmentPlan.frequency} payment`
        : 'flexible payment';
      break;
  }

  return heading;
}
