import React from 'react';
import {Disclosure} from '@headlessui/react';

import {getBillerSlugFromUrl} from 'lib/url';
import {
  QuestionMarkCircleIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/20/solid';
import {navigate} from 'lib/navigation/routes';

import storage from 'local-storage-fallback';
import {loadCredentials} from 'lib/auth/token';
import {useAuth} from 'lib/auth/Auth';

export const Navbar: React.FC = () => {
  const slug = getBillerSlugFromUrl();
  const isLoggedIn = loadCredentials()?.id_token;
  const {logout} = useAuth();

  const goHome = () => {
    if (
      storage.getItem('signed-up') !== null ||
      window.location.pathname.indexOf('/setup') === -1 ||
      window.location.pathname.indexOf('/setup/confirmation') !== -1
    ) {
      navigate('/biller/:slug', {slug});
    }
  };

  const getHelp = () => {
    navigate('/biller/:slug/support', {slug});
  };

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-20 overflow-hidden bg-white shadow-md"
    >
      {({open: __open}) => (
        <>
          <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            {isLoggedIn && (
              <div className="absolute md:hidden left-2 top-2">
                <button
                  onClick={logout}
                  className="flex flex-col items-center justify-center transition-opacity cursor-pointer opacity-70 hover:opacity-100"
                >
                  <ArrowLeftOnRectangleIcon className="w-8 h-8 text-gray-400" />
                  <span className="text-xs text-gray-600">Sign Out</span>
                </button>
              </div>
            )}
            <div className="flex justify-between h-16">
              <div className="flex justify-between flex-grow">
                <div className="flex justify-center flex-1">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center space-x-6 md:order-2">
                      <img
                        src={
                          slug
                            ? `/cms/biller/${slug}/logo.png`
                            : '/cms/payble-logo.png'
                        }
                        onClick={goHome}
                        className="relative cursor-pointer max-h-12"
                        alt={
                          slug && slug.length > 0
                            ? slug.replace(/-/g, ' ')
                            : 'Payble'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-0 flex items-center h-full gap-4 right-2">
              {isLoggedIn && (
                <button
                  onClick={logout}
                  className="flex-col items-center justify-center hidden transition-opacity cursor-pointer md:flex opacity-70 hover:opacity-100"
                >
                  <ArrowLeftOnRectangleIcon className="w-8 h-8 text-gray-400" />
                  <span className="text-xs text-gray-600">Sign Out</span>
                </button>
              )}
              {slug !== 'energy-on-v1' && (
                <div
                  onClick={getHelp}
                  className="flex flex-col items-center justify-center transition-opacity cursor-pointer opacity-70 hover:opacity-100"
                >
                  <QuestionMarkCircleIcon className="w-8 h-8 text-gray-400 " />
                  <span className="text-xs text-gray-600">Help</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};
