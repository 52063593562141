import React, {useEffect} from 'react';

import {getBillerSlugFromUrl} from 'lib/url';
import {ConfirmationInstalmentPlan} from '../../setup/components/ConfirmationInstalmentPlan';
import {useParams} from 'react-router';
import {Loading} from 'components/atoms/Loading';
import {navigate} from 'lib/navigation/routes';
import {TryAgain} from 'components/molecules/TryAgain';
import {useAPIQuery} from 'lib/api';
import {useUser} from 'lib/auth/useUser';

export const Confirmation: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const slug = getBillerSlugFromUrl();
  const user = useUser();

  useEffect(() => {
    document.title = 'Payble - Payment Plan Confirmation';
  }, []);

  const {
    data: instalmentPlan,
    isLoading: loading,
    error,
  } = useAPIQuery('getInstalmentPlan', {
    data: instalmentPlanId ? {instalmentPlanId} : undefined,
  });

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  // Handle loading states
  if (loading) return <Loading />;

  if (error)
    return (
      <TryAgain
        errorMessage={error.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug});
        }}
      />
    );
  if (!instalmentPlan) return <>No payment plan found</>;

  return (
    <ConfirmationInstalmentPlan
      billerSlug={slug}
      instalmentPlanId={instalmentPlanId}
      contact={user.contact}
    />
  );
};
