import {FC, ReactNode} from 'react';
import {
  FormInputNumberFormat,
  FormInputNumberFormatProps,
} from './FormInputNumberFormat';

export interface FormInputNumberProps extends FormInputNumberFormatProps {
  label?: ReactNode;
  value?: number;
  defaultValue?: number;
  name: string;
}

export const FormInputNumber: FC<FormInputNumberProps> = ({
  label,
  name,
  ...props
}) => {
  return (
    <FormInputNumberFormat
      name={name}
      label={label}
      {...props}
      displayType="input"
      setValueAs="float"
    />
  );
};
