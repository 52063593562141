import {z} from 'zod';
import {billerResponse} from '../schemas/biller';
import {defineHTTPEndpoint} from '../utils';

export const getSessionBiller = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getSessionBiller',
  queryKey: () => ['user', 'biller'],
  requestSchema: z.object({billerSlug: z.string().min(1).trim()}),
  responseSchema: billerResponse.nullable(),
});
