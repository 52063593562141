import {NoticeBanner} from 'components/molecules/NoticeBanner';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {AbsoluteDate} from 'payble-shared';
import {useState} from 'react';

export const PublicHolidayNotice = () => {
  const [isOpen, setOpen] = useState(false);
  const billerConfig = useBillerConfig();

  if (billerConfig.billerSlug === 'energy-on') {
    return <></>;
  }

  return (
    <NoticeBanner
      displayEndDate={AbsoluteDate.fromISO('2025-01-01')}
      content="Due to the public holidays in the festive period, payments may not be reflected in your account balance until early 2025"
      isOpen={isOpen}
      setOpen={setOpen}
      name="consumerBanner"
    />
  );
};
