import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';
import {addPaymentMethodResponse, paymentResponse} from '../schemas/payment';
import {zNZBankAccountNumber} from '../schemas';

export const getPayments = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /getPayments',
  queryKey: () => ['user', 'payments'],
  requestSchema: null,
  responseSchema: z.array(paymentResponse),
});

export const addPaymentMethod = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /addPaymentMethod',
  requestSchema: z.union([
    z.object({
      type: z.literal('card'),
      brand: z.string(),
      expiryMonth: z.number().int().min(1).max(12),
      expiryYear: z.number().int(),
      holderName: z.string(),
      last4: z.string(),
      numberToken: z.string(),
      numberTokenHmac: z.string(),
      referenceNumber: z.string(),
      usePaymentMethodForPlans: z.array(z.string()).optional(),
    }),
    z.object({
      type: z.literal('direct_debit'),
      accountName: z.string(),
      accountNumber: z.string(),
      bsb: z.string(),
      usePaymentMethodForPlans: z.array(z.string()).optional(),
    }),
    z.object({
      type: z.literal('nz_direct_debit'),
      accountName: z.string(),
      accountNumber: zNZBankAccountNumber,
      usePaymentMethodForPlans: z.array(z.string()).optional(),
    }),
  ]),
  responseSchema: addPaymentMethodResponse,
});
