import {DomainError} from './DomainError';
import {UnexpectedError} from './StandardErrors';

interface GraphQLError {
  originalError: Error;
}

const isGraphQLError = (error: unknown): error is GraphQLError =>
  typeof error === 'object' &&
  error !== null &&
  'originalError' in error &&
  (error as GraphQLError).originalError instanceof Error;

export function coerceToDomainError(error: unknown): DomainError {
  if (error instanceof DomainError) {
    return error;
  }
  if (isGraphQLError(error)) {
    return coerceToDomainError(error.originalError);
  }
  return UnexpectedError.wrap(error);
}
