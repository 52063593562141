import React, {useEffect} from 'react';
import NumberFormat from 'react-number-format';

import {Debbie} from 'components/organisms/Debbie';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';

import {navigate} from 'lib/navigation/routes';
import {PaymentMethodRow} from 'features/instalment-plan/components/PaymentMethodRow';

import _refiner from 'refiner-js';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {capitalize, formatToDollars} from 'payble-shared';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';
import {useAPIInvalidate, useAPIQuery} from '../../../lib/api';
import {ContactResponse} from 'payble-api-client/schemas';
import {useUser} from 'lib/auth/useUser';

type ConfirmationInstalmentPlanProps = {
  billerSlug: string;
  instalmentPlanId: string;
  contact?: ContactResponse | null;
};

export const ConfirmationInstalmentPlan: React.FC<
  ConfirmationInstalmentPlanProps
> = ({billerSlug, contact, instalmentPlanId}) => {
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(billerSlug);
  const billerConfig = useBillerConfig();
  const invalidate = useAPIInvalidate();
  const user = useUser();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    invalidate({queryKey: ['user']});

    if (billerSlug === 'city-of-kingston') {
      _refiner('showForm', '6e8d8fe0-fb61-11ec-9d73-493856f3d7b9');
    } else if (billerSlug === 'city-of-exampleton') {
      _refiner('showForm', '2ae93970-0cad-11ed-8aee-798d80f31984');
    } else if (billerSlug === 'moreton-bay') {
      _refiner('showForm', '6a1d1230-8d75-11ed-9577-1f6ec04f1f1d');
    }
  }, []);

  const {
    data: instalmentPlan,
    isLoading: loading,
    error,
  } = useAPIQuery('getInstalmentPlan', {
    data: instalmentPlanId ? {instalmentPlanId} : undefined,
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!instalmentPlan) return <ErrorMessage message="No payment plan" />;

  const biller = user.biller;

  if (!biller || !contact) return <div>No contact or biller</div>;

  const paymentMethod = contact?.paymentMethods?.find(
    x => x.id === instalmentPlan?.paymentMethodId
  );
  if (!paymentMethod) {
    return <div>No payment method</div>;
  }

  const nextInstalmentDueAt = instalmentPlan.nextInstalmentDueAt;

  const debbieTitle = nextInstalmentDueAt
    ? `Your payments have been scheduled to start on ${nextInstalmentDueAt.toFormat(
        'dd LLL yyyy'
      )}`
    : '';

  let debbieMessage = `All done ${contact.givenName}`;
  if (nextInstalmentDueAt) {
    debbieMessage += `, your first payment to ${
      biller.name
    } will be made ${nextInstalmentDueAt.toFormat(
      'dd LLL yyyy'
    )}. We'll send a receipt via SMS - just click the link if you ever need to view or modify your schedule.`;
  }

  return (
    <div data-testid="payInFullConfirmation">
      <Debbie title={debbieTitle} message={debbieMessage} />
      <div className="mt-5 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <dl className="grid grid-cols-1 gap-4 text-sm text-gray-600 sm:grid-cols-2">
            <div className="col-span-2 pb-4 border-b">
              <h3
                className="text-lg font-medium leading-6 text-gray-900"
                data-testid="header"
              >
                Payment plan {instalmentPlan.account.externalId}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {capitalize(
                  billerConfig.getAccountTypeTitle(instalmentPlan.account.type)
                )}{' '}
                for <br />
                {instalmentPlan.account.description}
              </p>
            </div>
            <div>
              <dt className="mb-2 font-medium text-gray-900">
                Contact Information
              </dt>
              <dd>
                <address className="not-italic">
                  <span className="block break-words">
                    {contact.givenName} {contact.familyName}
                  </span>
                  <span className="block">
                    <PhoneNumberFormat
                      value={contact.mobile}
                      region={billerConfig.region}
                    />
                  </span>
                  <span className="block break-words">{contact.email}</span>
                </address>
              </dd>
            </div>
            <div>
              <dt className="mb-2 font-medium text-gray-900">
                Payment Information
              </dt>
              <PaymentMethodRow paymentMethod={paymentMethod} />
            </div>
          </dl>

          <dl className="mt-6 space-y-3 text-sm font-medium text-gray-500">
            <div className="flex items-center justify-between pt-3 text-gray-900 border-t">
              <dt className="text-sm">Total</dt>
              <dd className="text-sm">
                <NumberFormat
                  value={formatToDollars(instalmentPlan.amount)}
                  displayType={'text'}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </dd>
            </div>
          </dl>
          <dl className="mt-3 space-y-3 text-sm font-medium text-gray-500">
            <div className="relative flex items-center justify-between pt-3 text-gray-900 border-t">
              <dt className="text-sm">
                {instalmentPlan.instalments.length} Payments
              </dt>
              <dd className="text-sm">
                <NumberFormat
                  value={(
                    (instalmentPlan.amount as number) /
                    instalmentPlan.instalments.length /
                    100
                  ).toFixed(2)}
                  displayType={'text'}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </dd>
              <button
                onClick={() =>
                  navigate(
                    '/biller/:slug/instalment-plan/:instalmentPlanId/schedule',
                    {slug: billerSlug, instalmentPlanId}
                  )
                }
                className="absolute px-4 py-2 text-xs text-gray-800 bg-gray-100 border-transparent rounded-lg right-20 hover:bg-gray-200 top-2 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-gray-200"
              >
                See more
              </button>
            </div>
          </dl>
          <RolloverNotice showNotice={hasYearlyRollOver} />
        </div>
      </div>
      <div className="flex items-center flex-1">
        <button
          onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to home
        </button>
      </div>
    </div>
  );
};
