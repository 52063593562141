import {FC, ReactNode} from 'react';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import {Textarea, TextareaProps} from './Textarea';

export interface FormTextareaProps
  extends Omit<TextareaProps, 'type' | 'size'> {
  size?: 'sm' | 'default';
  label?: ReactNode;
  name: string;
}

export const FormTextarea: FC<FormTextareaProps> = ({
  label,
  name,
  ...props
}) => {
  const {id, field, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <Textarea
        id={id}
        {...props}
        {...field}
        disabled={
          props.disabled || field.disabled || form.formState.isSubmitting
        }
      />
    </FormField>
  );
};
