import React, {useEffect} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {useRemovePaymentMethodMutation} from 'lib/graphql/API';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {navigate} from 'lib/navigation/routes';
import {HomeIcon} from '@heroicons/react/20/solid';
import {Debbie} from 'components/organisms/Debbie';
import {ProfileMenu} from './components/ProfileMenu';
import {PaymentMethodRow} from 'features/instalment-plan/components/PaymentMethodRow';
import {
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {classNames} from 'lib/styles';
import {useEmailVerificationSend} from './pages/useEmailVerificationSend';
import {MiniSpinner} from '../../components/atoms/Spinner';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {MaxContainer} from 'payble-ui';
import {useAPIInvalidate, useAPIQuery} from 'lib/api';
import {useUser} from 'lib/auth/useUser';

export const Profile: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const user = useUser();
  const invalidate = useAPIInvalidate();

  const emailVerification = useEmailVerificationSend();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    invalidate({queryKey: ['user']});
    document.title = 'Payble - Profile';
  }, []);

  const {
    data: instalmentPlans,
    isLoading: instalmentPlanLoading,
    error,
  } = useAPIQuery('getInstalmentPlans', {
    query: {
      enabled: true,
      retry: 1,
    },
  });

  const [removePaymentMethod, {loading: removePaymentMethodLoading}] =
    useRemovePaymentMethodMutation();

  if (instalmentPlanLoading || removePaymentMethodLoading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!user.contact) return <div>No contact</div>;

  const onRemovePaymentMethod = async (paymentMethodId: string) => {
    await removePaymentMethod({
      variables: {
        input: {
          paymentMethodId,
        },
      },
    });
    invalidate({queryKey: ['user']});
  };

  const paymentMethods = user.contact?.paymentMethods
    ?.filter(pm => ['active', 'disabled'].includes(pm.status))
    ?.map(x => {
      let plans = 0;
      if (instalmentPlans) {
        plans =
          instalmentPlans
            ?.filter(x => x.status !== 'completed' && x.status !== 'cancelled')
            ?.filter(ip => ip.paymentMethodId === x.id)?.length ?? 0;
      }

      const showDisabledRemoveTooltip = plans > 0;

      return (
        <div key={x.id} className="relative flex items-start py-4">
          <PaymentMethodRow paymentMethod={x} />
          <div className="flex items-center ml-3 place-self-center">
            <button
              disabled={showDisabledRemoveTooltip}
              onClick={() => onRemovePaymentMethod(x.id)}
              className="items-center justify-center flex-1 px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition bg-white border border-gray-300 rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <TooltipMessage
                tooltipBgColorAndHue="gray-100"
                tooltipTextColorAndHue="gray-800"
                tooltipText={
                  showDisabledRemoveTooltip
                    ? 'You cannot remove this payment method as it is linked to an active payment plan'
                    : ''
                }
              >
                <span className="flex items-center">
                  Remove
                  {showDisabledRemoveTooltip && (
                    <QuestionMarkCircleIcon className="w-4 h-4 ml-2 text-gray-700" />
                  )}
                </span>
              </TooltipMessage>
            </button>
          </div>
        </div>
      );
    });

  let profileName = 'Your';
  if (user.contact?.givenName) {
    profileName = user.contact?.givenName;
    profileName = profileName.endsWith('s')
      ? `${profileName}'`
      : `${profileName}'s`;
  }

  const {
    title,
    givenName,
    familyName,
    mobile,
    mobileVerified,
    sendingEmailReceipts,
  } = user.contact;

  const onChangeEmail = () => {
    navigate('/biller/:slug/profile/edit', {
      slug: billerSlug,
    });
  };

  return (
    <MaxContainer>
      <Debbie
        title={'Manage your profile'}
        message="You can remove payment methods and edit your details here."
      />

      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() =>
                      navigate('/biller/:slug', {slug: billerSlug})
                    }
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate('/biller/:slug/profile', {
                        slug: billerSlug,
                      })
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {profileName} profile
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex pl-4">
          <ProfileMenu slug={billerSlug} />
        </div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Profile Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              We use these details to contact you and link your payments with
              your account.
            </p>
          </div>
          <div className="grid grid-cols-3 mt-4 mb-8 text-sm gap-y-2 gap-x-4">
            <div className="font-semibold text-gray-600">Title</div>
            <div className="col-span-2">{title}</div>
            <div className="font-semibold text-gray-600">Given name</div>
            <div className="col-span-2">{givenName}</div>
            <div className="font-semibold text-gray-600">Family name</div>
            <div className="col-span-2">{familyName}</div>
            <div className="font-semibold text-gray-600">Mobile</div>
            <div className="col-span-2">
              <ShieldCheckIcon
                className={classNames(
                  'inline-flex mr-2 h-5 w-5',
                  mobileVerified ? 'text-green-400' : 'text-gray-400'
                )}
                aria-hidden="true"
              />
              <PhoneNumberFormat value={mobile} region={billerConfig.region} />
            </div>

            <div className="font-semibold text-gray-600">Payment Receipts</div>
            <div className="col-span-2">
              {sendingEmailReceipts ? 'Sending to email' : 'Not enabled'}
            </div>

            <div className="font-semibold text-gray-600">Email</div>
            <div className="flex flex-col col-span-2">
              <span className="inline">
                <ShieldCheckIcon
                  className={classNames(
                    'inline-flex mr-2 h-5 w-5',
                    emailVerification.state === 'VERIFIED'
                      ? 'text-green-400'
                      : 'text-gray-400'
                  )}
                  aria-hidden="true"
                />
                {emailVerification.email}
              </span>

              <div className="flex flex-col space-y-1">
                {emailVerification.state === 'LOADING' && <MiniSpinner />}
                {['PENDING', 'RESEND', 'ERROR'].includes(
                  emailVerification.state
                ) && (
                  <>
                    <div className="flex flex-row space-x-4">
                      <span
                        onClick={emailVerification.send}
                        className="text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-700"
                      >
                        {emailVerification.state === 'RESEND'
                          ? 'Send again'
                          : 'Verify email'}
                      </span>
                      <div className="text-blue-500">|</div>

                      <span
                        className="text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-700"
                        onClick={onChangeEmail}
                      >
                        Change email
                      </span>
                    </div>
                    {emailVerification.state === 'ERROR' && (
                      <div className="text-sm font-medium text-red-500">
                        {emailVerification.errorMessage}
                      </div>
                    )}
                  </>
                )}

                {emailVerification.state === 'SENT' && (
                  <div className="text-sm font-medium text-gray-400">
                    Verification email sent
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Payment Methods
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You can use different payment methods for each payment plan. You
              can also select different payment methods for one-off payments.
            </p>
          </div>
          <div className="divide-y divide-gray-200">{paymentMethods}</div>
        </div>
      </div>
    </MaxContainer>
  );
};
