import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import {cva, cn, type VariantProps} from '../lib/utils';

const labelVariants = cva(
  'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      size: {
        default: 'text-base font-medium',
        xl: 'text-xl font-bold',
        sm: 'text-sm font-medium',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

export interface LabelProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
      'className'
    >,
    VariantProps<typeof labelVariants> {
  className?: string;
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  LabelProps
>(({className, size, ...props}, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({size}), className)}
    {...props}
  />
));

Label.displayName = LabelPrimitive.Root.displayName;

export {Label};
