/* eslint-disable eqeqeq */
import React, {useState, useEffect, useRef, ReactNode} from 'react';
import {DateTime} from 'luxon';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  err,
  validateCardholder,
  validateEmail,
  validateFamilyName,
  validateGivenName,
} from 'payble-shared';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {classNames} from 'lib/styles';
import {XCircleIcon} from '@heroicons/react/24/solid';
import {getBillerSlugFromUrl} from 'lib/url';
import {PaymentMethodAgreement} from 'components/organisms/PaymentMethodAgreement';
import {goBack} from 'lib/navigation/routes';
import {TokenExCreditCardFormRef} from 'lib/tokenex/useTokenexIframe';
import {ApplyPaymentMethodToPlans} from './ApplyPaymentMethodToPlans';
import {TokenExCreditCardNumber} from './TokenExCreditCardNumber';
import {TokenExCreditCardCvv} from './TokenExCreditCardCvv';
import {StyledCheckbox} from 'features/setup/components/StyledCheckbox';

type ContactDetails = {
  givenName: string;
  familyName: string;
  email: string;
};

type TokenExOnCompleteValues = {
  card: {
    numberToken: string;
    numberTokenHmac: string;
    expiryMonth: number;
    expiryYear: number;
    brand: string;
    last4: string;
    holderName: string;
    referenceNumber: string;
  };
  contact: ContactDetails;
  usePaymentMethodForPlans?: string[];
  sendReceipts?: boolean;
};

type TokenExCreditCardFormProps = {
  mode?: 'add';
  disabled: boolean;
  anonymous?: boolean;
  proceedButtonText?: ReactNode;
  onCardComplete: ({card, contact}: TokenExOnCompleteValues) => Promise<void>;
  contact?: ContactDetails | null;
  onCompleteError?: string;
  showSendReceipts?: boolean;
};

type ErrorMessage = string | undefined;

type State = 'input' | 'requesting' | 'confirmed';

export const TokenExCreditCardForm: React.FC<TokenExCreditCardFormProps> = ({
  disabled,
  anonymous,
  proceedButtonText,
  onCompleteError,
  onCardComplete,
  contact,
  mode,
  showSendReceipts = false,
}) => {
  const billerSlug = getBillerSlugFromUrl();
  const [state, setState] = useState<State>('input');
  const [error, setError] = useState<ErrorMessage>();
  const [cardNumberHasBlurred, setCardNumberHasBlurred] = useState(false);
  const [cvvHasBlurred, setCvvHasBlurred] = useState(false);
  const [usePaymentMethodForPlans, setUsePaymentMethodForPlans] = useState<
    string[]
  >([]);
  const [cardNumberError, setCardNumberError] = useState<ErrorMessage>();
  const [cardTypeError, setCardTypeError] = useState<ErrorMessage>();
  const [cardExpiryDate, setCardExpiryDate] = useState('');
  const [cardExpiryDateError, setCardExpiryDateError] =
    useState<ErrorMessage>();

  const [cardholder, setCardholder] = useState(
    contact?.givenName && contact?.familyName
      ? `${contact.givenName} ${contact.familyName}`
      : ''
  );
  const [cardholderError, setCardholderError] = useState<ErrorMessage>();

  const [givenName, setGivenName] = useState(contact ? contact.givenName : '');
  const [givenNameError, setGivenNameError] = useState<ErrorMessage>();
  const [familyName, setFamilyName] = useState(
    contact ? contact.familyName : ''
  );
  const [cardValid, setCardValid] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const [cvvError, setCvvError] = useState<ErrorMessage>();

  const [familyNameError, setFamilyNameError] = useState<ErrorMessage>();
  const [email, setEmail] = useState(contact ? contact.email : '');
  const [emailError, setEmailError] = useState<ErrorMessage>();
  const [emailErrorVisible, setEmailErrorVisibile] = useState<boolean>(false);

  const [sendReceipts, setSendReceipts] = useState<boolean | undefined>();

  useEffect(() => {
    setError(onCompleteError);
  }, [onCompleteError]);

  const [hasAgreed, setHasAgreed] = useState(false);
  const cardNumberRef = useRef<TokenExCreditCardFormRef>(null);

  const canRequest =
    state === 'input' &&
    hasAgreed &&
    cardValid &&
    cvvValid &&
    !cvvError &&
    !cardTypeError &&
    !cardNumberError &&
    cardExpiryDate !== '' &&
    !cardExpiryDateError &&
    givenName !== '' &&
    !givenNameError &&
    familyName !== '' &&
    !familyNameError &&
    (contact?.email ? true : email !== '' && !emailError);

  const onCardExpiryChange = (values: NumberFormatValues) => {
    const date = DateTime.fromFormat(values.formattedValue, 'MM/yy');
    if (date.isValid) {
      if (date.endOf('month') < DateTime.local()) {
        setCardExpiryDateError('Expiry date must be in the future');
        return;
      }

      setCardExpiryDate(values.formattedValue);
      setCardExpiryDateError(undefined);
    } else {
      setCardExpiryDateError('Please enter a valid expiry date');
    }
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = validateEmail(e.target.value);
    if (!err(email)) {
      setEmail(e.target.value);
      setEmailError(undefined);
    } else {
      setEmailError(email.message);
    }
  };

  const onGivenNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const givenName = validateGivenName(e.target.value);
    if (!err(givenName)) {
      setGivenName(e.target.value);
      setGivenNameError(undefined);
    } else {
      setGivenNameError(givenName.message);
    }
  };

  const onFamilyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const familyName = validateFamilyName(e.target.value);
    if (!err(familyName)) {
      setFamilyName(e.target.value);
      setFamilyNameError(undefined);
    } else {
      setFamilyNameError(familyName.message);
    }
  };

  const onCardholderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardholder = validateCardholder(e.target.value);
    if (!err(cardholder)) {
      setCardholder(e.target.value);
      setCardholderError(undefined);
    } else {
      setCardholderError(cardholder.message);
    }
  };

  const onError = (data: any) => {
    setCardNumberHasBlurred(true);
    setCardNumberError(data.error);
  };

  const onBlur = () => {
    setCardNumberHasBlurred(true);
  };

  const onCvvBlur = () => {
    setCvvHasBlurred(true);
  };

  const onValidate = (data: any) => {
    if (data.isValid != null) {
      setCardNumberError(
        data.isValid ? undefined : 'Please enter a valid card number'
      );
      setCardValid(data.isValid);
    }
    if (data.isCvvValid != null) {
      setCvvError(
        data.isCvvValid ? undefined : 'Please enter a valid CVV number'
      );
      setCvvValid(data.isCvvValid);
    }
    if (data.isAccepted != null) {
      setCardTypeError(
        data.isAccepted ? undefined : 'Sorry, we do not accept this card type'
      );
    }
  };

  const request = async () => {
    if (!canRequest) {
      console.warn('Cannot request token while form is invalid');
      return;
    }
    try {
      setState('requesting');
      setError(undefined);

      const expiry = DateTime.fromFormat(cardExpiryDate, 'MM/yy');
      if (cardNumberRef.current == null) {
        throw new Error('cardNumberRef.current is undefined');
      }

      const cardData = await cardNumberRef.current.tokenize();

      const card = {
        holderName: cardholder,
        expiryMonth: parseInt(expiry.toFormat('MM')),
        expiryYear: parseInt(expiry.toFormat('yy')),
        last4: cardData.lastFour,
        numberToken: cardData.token,
        referenceNumber: cardData.referenceNumber,
        numberTokenHmac: cardData.tokenHMAC,
        brand: cardData.cardType,
      };

      const contact = {
        givenName,
        familyName,
        email,
      };

      await onCardComplete({
        card,
        contact,
        usePaymentMethodForPlans,
        sendReceipts,
      });
      setState('confirmed');
    } catch (e) {
      setError((e as Error).message);
      setState('input');
    }
  };

  return (
    <div id="credit-card-form">
      <div className="mt-2 md:col-span-2">
        <div className="md:mt-0 md:col-span-2">
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-2 gap-y-6">
                {(!contact?.givenName || !contact?.familyName) && (
                  <>
                    <div className="col-span-3">
                      <label
                        htmlFor="givenName"
                        className="block text-sm font-bold text-navy"
                      >
                        Given name
                      </label>
                      <input
                        type="text"
                        id="givenName"
                        name="givenName"
                        placeholder="Given name"
                        autoComplete="cc-given-name"
                        className={classNames(
                          'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm text-sm border-gray-300 rounded-md',
                          !givenNameError ? 'border-gray-300' : 'border-red-600'
                        )}
                        defaultValue={givenName}
                        onChange={onGivenNameChange}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="familyName"
                        className="block text-sm font-bold text-navy"
                      >
                        Family name
                      </label>
                      <input
                        type="text"
                        id="familyName"
                        name="familyName"
                        placeholder="Family name"
                        autoComplete="cc-family-name"
                        className={classNames(
                          'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm text-sm border-gray-300 rounded-md',
                          !familyNameError
                            ? 'border-gray-300'
                            : 'border-red-600'
                        )}
                        defaultValue={familyName}
                        onChange={onFamilyNameChange}
                        disabled={disabled}
                      />
                    </div>
                    {givenNameError || familyNameError ? (
                      <ul className="col-span-6 -mt-3 -mb-3">
                        {givenNameError && (
                          <li className="mt-1 text-sm text-red-600">
                            {givenNameError}
                          </li>
                        )}
                        {familyNameError && (
                          <li className="mt-1 text-sm text-red-600">
                            {familyNameError}
                          </li>
                        )}
                      </ul>
                    ) : null}
                  </>
                )}

                {!contact?.email && (
                  <>
                    <div className="col-span-6">
                      <label
                        htmlFor="email"
                        className="block text-sm font-bold text-navy"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        data-testid="email"
                        id="email"
                        defaultValue={email}
                        onChange={onEmailChange}
                        onBlur={() => setEmailErrorVisibile(true)}
                        className={classNames(
                          'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm text-sm border-gray-300 rounded-md',
                          !emailError ? 'border-gray-300' : 'border-red-600'
                        )}
                        disabled={disabled}
                      />
                    </div>
                    {emailError && emailErrorVisible ? (
                      <ul className="col-span-6 -mt-3 -mb-3">
                        {emailError && (
                          <li className="mt-1 text-sm text-red-600">
                            {emailError}
                          </li>
                        )}
                      </ul>
                    ) : null}
                  </>
                )}
                <div className="col-span-6">
                  <label
                    htmlFor="cardholder"
                    className="block text-sm font-bold text-navy"
                  >
                    Name on card
                  </label>
                  <input
                    type="text"
                    id="cardholder"
                    name="cardholder"
                    autoComplete="cc-cardholder"
                    className={classNames(
                      'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm text-sm border-gray-300 rounded-md',
                      !cardholderError ? 'border-gray-300' : 'border-red-600'
                    )}
                    defaultValue={cardholder}
                    onChange={onCardholderChange}
                    disabled={disabled}
                  />
                </div>
                {cardholderError ? (
                  <ul className="col-span-6 -mt-3 -mb-3">
                    <li className="mt-1 text-sm text-red-600">
                      {cardholderError}
                    </li>
                  </ul>
                ) : null}
                <div className="z-0 col-span-6">
                  <TokenExCreditCardNumber
                    ref={cardNumberRef}
                    anonymous={anonymous}
                    billerSlug={billerSlug}
                    onBlur={onBlur}
                    onValidate={onValidate}
                    onError={onError}
                  />
                  {cardNumberHasBlurred &&
                    (cardNumberError || cardTypeError) && (
                      <div
                        className="mt-1 text-sm text-red-600"
                        id="error-message-card-type"
                      >
                        {cardNumberError || cardTypeError}
                      </div>
                    )}
                </div>

                <div className="z-10 col-span-3">
                  <label
                    htmlFor="expiration-date"
                    className="block text-sm font-bold text-navy"
                  >
                    Expiry
                  </label>
                  <div className="mt-1">
                    <NumberFormat
                      format="##/##"
                      placeholder="MM/YY"
                      mask={['M', 'M', 'Y', 'Y']}
                      name="expiration-date"
                      id="expiration-date"
                      autoComplete="cc-exp"
                      className={classNames(
                        'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm text-sm border-gray-300 rounded-md placeholder-gray-400 z-50',
                        !cardExpiryDateError
                          ? 'border-gray-300'
                          : 'border-red-600'
                      )}
                      defaultValue={cardExpiryDate}
                      onValueChange={onCardExpiryChange}
                      disabled={disabled}
                    />
                  </div>
                  {cardExpiryDateError && (
                    <div className="mt-1 text-sm text-red-600">
                      {cardExpiryDateError}
                    </div>
                  )}
                </div>

                <div className="z-0 col-span-3">
                  <TokenExCreditCardCvv
                    ref={cardNumberRef}
                    anonymous={anonymous}
                    billerSlug={billerSlug}
                    onCvvBlur={onCvvBlur}
                    onValidate={onValidate}
                    onError={onError}
                  />
                  {cvvHasBlurred && cvvError && (
                    <div className="mt-1 text-sm text-red-600">{cvvError}</div>
                  )}
                </div>

                {mode === 'add' && (
                  <ApplyPaymentMethodToPlans
                    onChange={setUsePaymentMethodForPlans}
                  />
                )}
                {showSendReceipts && (
                  <div className="w-full col-span-6">
                    <StyledCheckbox
                      checked={!!sendReceipts}
                      name="email-payment-receipts"
                      id="email-payment-receipts"
                      onClick={() => setSendReceipts(!sendReceipts)}
                    >
                      Send payment receipts to my email
                    </StyledCheckbox>
                  </div>
                )}

                <hr />

                <PaymentMethodAgreement
                  paymentMethodType="card"
                  callback={value => setHasAgreed(value)}
                  disabled={disabled}
                />
              </div>
            </div>
            {error ? (
              <div className="px-4 py-3 -mt-10 bg-white sm:px-6">
                <div className="p-4 rounded-md bg-red-50">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="w-5 h-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3
                        className="text-sm font-medium text-red-800"
                        id="error-message-card"
                      >
                        {error}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          disabled={!canRequest}
          data-testid="acknowledgeAndContinue"
          onClick={request}
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {state !== 'input' && <ButtonSpinner />}
          {proceedButtonText || 'Acknowledge and continue'}
        </button>
        <button
          type="button"
          onClick={goBack}
          disabled={state === 'requesting'}
          className="w-full mt-6 text-blue-600 transition hover:text-blue-700 center"
        >
          Back
        </button>
      </div>
    </div>
  );
};
