import React, {ReactNode, useState} from 'react';
import {api, useAPIMutation} from 'lib/api';
import {history} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {clear, safeValidate, storeCredentials} from './token';
import {DomainError} from 'payble-shared/src/errs';
import {analytics} from '../../analytics/hooks/useAnalytics';

interface AuthContext {
  sendOTPError: DomainError | null;
  sendOTP: (params: {
    mobileNumber: string;
  }) => Promise<{formattedPhoneNumber: string}>;
  verifyOTPError: DomainError | null;
  verifyOTP: (params: {
    mobileNumber: string;
    verificationCode: string;
  }) => Promise<{id_token: string}>;
  logout: () => Promise<void>;
  isLoggedIn: boolean;
}

export const Context = React.createContext<AuthContext>({} as AuthContext);

export const useAuth = () => React.useContext(Context);

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  const {billerSlug} = useBillerConfig();
  const [isLoggedIn, setIsLoggedIn] = useState(safeValidate());
  const [authPage, setAuthPage] = useState(window.location.pathname);

  // Re-validate auth on page change
  history.listen(() => {
    setIsLoggedIn(safeValidate());
    setAuthPage(window.location.pathname);
  });

  const logoutMutation = useAPIMutation('logout', {
    query: {
      onMutate: () => {
        analytics.addEvent('logged_out');
      },
      onSuccess: () => {
        clear();
        setIsLoggedIn(false);
        window.location.href = `/biller/${billerSlug}/login#logged-out`;
      },
    },
  });

  const loginMutation = useAPIMutation('loginStart', {
    query: {
      onSuccess: () => {
        analytics.addEvent('auth_sms_sent');
      },
      onError: () => {
        analytics.addEvent('auth_sms_failed');
      },
    },
  });

  const verifyMutation = useAPIMutation('loginComplete', {
    query: {
      onSuccess: (credentials: {id_token: string}) => {
        storeCredentials(credentials);
        api.setAuth({
          type: 'consumer',
          token: credentials.id_token,
        });
        setIsLoggedIn(true);
      },
      onError: () => {
        analytics.addEvent('auth_code_failed');
      },
    },
  });

  const contextValue = React.useMemo<AuthContext>(
    () => ({
      sendOTPError: loginMutation.error,
      sendOTP: ({mobileNumber}) =>
        loginMutation.mutateAsync({mobileNumber, billerSlug}),
      verifyOTPError: verifyMutation.error,
      verifyOTP: ({mobileNumber, verificationCode}) =>
        verifyMutation.mutateAsync({
          mobileNumber,
          billerSlug,
          verificationCode,
        }),
      logout: async () => {
        await logoutMutation.mutateAsync(null as never); //why?
      },
      isLoggedIn,
      authPage,
    }),
    [loginMutation, verifyMutation, logoutMutation, isLoggedIn, authPage]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
