import React, {useEffect} from 'react';
import NumberFormat from 'react-number-format';

import {Debbie} from 'components/organisms/Debbie';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';

import {PaymentStatus, useGetPaymentQuery} from 'lib/graphql/API';
import {navigate} from 'lib/navigation/routes';
import {PaymentMethodRow} from 'features/instalment-plan/components/PaymentMethodRow';

import _refiner from 'refiner-js';
import {formatToDollars, toTitleCase} from 'payble-shared';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';
import {MaxContainer} from 'payble-ui';
import {useAPIInvalidate} from 'lib/api';
import {useUser} from 'lib/auth/useUser';

type ConfirmationPayInFullProps = {
  billerSlug: string;
  paymentId: string;
  manage: boolean;
};

const getPaymentMessage = (status: PaymentStatus) => {
  if (status === PaymentStatus.Succeeded) {
    return 'Your payment was successful.';
  }

  return `Your payment is ${status}.`;
};

export const ConfirmationPayInFull: React.FC<ConfirmationPayInFullProps> = ({
  billerSlug,
  paymentId,
  manage,
}) => {
  const billerConfig = useBillerConfig();
  const user = useUser();
  const invalidate = useAPIInvalidate();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    invalidate({queryKey: ['user']});

    if (billerSlug === 'city-of-kingston') {
      _refiner('showForm', 'c580a0c0-0174-11ed-ab64-df297cf88894');
    } else if (billerSlug === 'city-of-exampleton') {
      _refiner('showForm', '2ae93970-0cad-11ed-8aee-798d80f31984');
    } else if (billerSlug === 'moreton-bay') {
      _refiner('showForm', '78b8d140-8d75-11ed-a49f-cf738a0d2716');
    }
  }, []);

  const {data, loading, error} = useGetPaymentQuery({
    variables: {
      id: paymentId,
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data?.payment) return <ErrorMessage message="No payment plan" />;

  const biller = user.biller;
  const contact = user.contact;

  if (!biller || !contact) return <div>No contact or biller</div>;

  const paymentMethod = contact.paymentMethods?.find(
    x => x.id === data.payment?.paymentMethodId
  );

  const debbieTitle = manage
    ? `View payment for #${data.payment.account?.externalId}`
    : `All done ${contact.givenName}!`;

  const debbieMessage = manage
    ? `Review details for ${data.payment.status} payment`
    : `${getPaymentMessage(
        data.payment.status
      )} You'll receive a receipt via SMS.`;

  return (
    <MaxContainer data-testid="payInFullConfirmation">
      <Debbie title={debbieTitle} message={debbieMessage} />
      <div className="mt-5 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <div className="grid grid-cols-1 gap-4 text-sm text-gray-600 md:grid-cols-2">
            <div className="pb-4 border-b md:col-span-2">
              <h3
                className="text-lg font-medium leading-6 text-gray-900"
                data-testid="header"
              >
                Payment {data?.payment?.account?.externalId}
                {data?.payment?.status === 'failed' && (
                  <span className="text-red-600"> (Failed)</span>
                )}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {toTitleCase(data?.payment?.account?.type as string)} for <br />{' '}
                {data?.payment?.account?.description}
              </p>
            </div>
            <div className="">
              <dt className="mb-2 font-medium text-gray-900">
                Contact Information
              </dt>
              <dd>
                <address className="not-italic">
                  <span className="block break-words">
                    {contact.givenName} {contact.familyName}
                  </span>
                  <span className="block">
                    <PhoneNumberFormat
                      value={contact.mobile}
                      region={billerConfig.region}
                    />
                  </span>
                  <span className="block break-words">{contact.email}</span>
                </address>
              </dd>
            </div>
            {paymentMethod && (
              <div className="">
                <dt className="mb-2 font-medium text-gray-900">
                  Payment Information
                </dt>
                <PaymentMethodRow paymentMethod={paymentMethod} />
              </div>
            )}
          </div>
          <dl className="mt-4 space-y-6 text-sm font-medium text-gray-500">
            <div className="flex items-center justify-between pt-2 text-gray-900 border-t">
              <dt className="text-base">Total</dt>
              <dd className="text-base">
                {data?.payment?.status === 'failed' && (
                  <span className="text-red-600">(Failed) </span>
                )}
                <NumberFormat
                  value={formatToDollars(data.payment.amount)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="flex items-center flex-1">
        <button
          onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to home
        </button>
      </div>
    </MaxContainer>
  );
};
