import {DateTime} from 'luxon';
import * as time from './time';

export interface DateDurationLike {
  days?: number;
  weeks?: number;
  months?: number;
  quarters?: number;
  years?: number;
}

export class AbsoluteDate {
  constructor(
    public day: number,
    public month: number,
    public year: number
  ) {
    this.validate();
  }

  /**
   * This discards any timezone information so only use this when you are
   * positive that the date represents an absolute date.
   */
  static fromDB(date: `${number}-${number}-${number}` | Date) {
    if (typeof date === 'string') {
      return AbsoluteDate.fromISO(date);
    } else {
      return new AbsoluteDate(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear()
      );
    }
  }

  static fromDate(date: Date, context: time.DateContext) {
    const parsed = time.toDateTime(date, context);
    return AbsoluteDate.fromDateTime(parsed);
  }

  static fromDateTime(dateTime: DateTime) {
    return new AbsoluteDate(dateTime.day, dateTime.month, dateTime.year);
  }

  static fromExpiry(args: {month: number; year: number}) {
    const {month} = args;
    const year = args.year < 2000 ? args.year + 2000 : args.year;
    return new AbsoluteDate(1, month, year).endOf('month');
  }

  static fromFormat(
    formats: string[],
    date: string,
    context: time.DateContext | null
  ) {
    const parsed = time.fromFormat(formats, date, context);
    return AbsoluteDate.fromDateTime(parsed);
  }

  static maybeFromFormat(
    ...args: Parameters<(typeof AbsoluteDate)['fromFormat']>
  ) {
    try {
      return AbsoluteDate.fromFormat(...args);
    } catch (e) {
      return null;
    }
  }

  static mayBeFromDate(date: Date | null, context: time.DateContext) {
    if (!date) return null;
    try {
      return AbsoluteDate.fromDate(date, context);
    } catch (e) {
      return null;
    }
  }

  static today(context: time.DateContext) {
    return AbsoluteDate.fromDateTime(time.now(context));
  }

  static fromISO(iso: string) {
    const [year, month, day, ...rest] = iso.split('-').map(Number);
    if (rest.length || isNaN(day)) {
      throw new Error(`Invalid ISO absolute date. Too many parts: ${iso}`);
    }
    return new AbsoluteDate(day, month, year);
  }

  static fromObject(obj: {day: number; month: number; year: number}) {
    return new AbsoluteDate(obj.day, obj.month, obj.year);
  }

  static parseISO(iso: string, context: time.DateContext) {
    const parsed = DateTime.fromISO(iso, time.getOptions(context));
    return AbsoluteDate.fromDateTime(parsed);
  }

  static maybeFromISO(iso: unknown) {
    if (iso instanceof AbsoluteDate) {
      return iso;
    }

    if (typeof iso !== 'string') {
      return null;
    }
    try {
      return AbsoluteDate.fromISO(iso);
    } catch (e) {
      return null;
    }
  }

  get shortYear() {
    if (this.year > 2000) {
      return this.year - 2000;
    }
    return this.year;
  }

  toDate(context: time.DateContext) {
    return this.toDateTime(context).toJSDate();
  }

  toLocalDateTime() {
    return DateTime.fromISO(this.toISO());
  }

  toDateTime(context: time.DateContext) {
    return DateTime.fromObject(
      {
        day: this.day,
        month: this.month,
        year: this.year,
      },
      time.getOptions(context)
    );
  }

  toComparable() {
    return this.year * 10000 + this.month * 100 + this.day;
  }

  endOf(unit: 'month' | 'year') {
    const end = this.utcDateTime.endOf(unit);
    return AbsoluteDate.fromDateTime(end);
  }
  startOf(unit: 'day' | 'month' | 'year') {
    const start = this.utcDateTime.startOf(unit);
    return AbsoluteDate.fromDateTime(start);
  }

  set(args: {day?: number; month?: number; year?: number}) {
    return new AbsoluteDate(
      args.day ?? this.day,
      args.month ?? this.month,
      args.year ?? this.year
    );
  }

  plus(args: DateDurationLike) {
    const added = this.utcDateTime.plus(args);
    return AbsoluteDate.fromDateTime(added);
  }

  minus(args: DateDurationLike) {
    const minus = this.utcDateTime.minus(args);
    return AbsoluteDate.fromDateTime(minus);
  }

  toISO(): `${number}-${number}-${number}` {
    const day = this.day.toString().padStart(2, '0');
    const month = this.month.toString().padStart(2, '0');
    const year = this.year.toString().padStart(4, '0');
    return `${year}-${month}-${day}` as `${number}-${number}-${number}`;
  }

  isBefore(date: AbsoluteDate) {
    return this.utcMS < date.utcMS;
  }

  isAfter(date: AbsoluteDate) {
    return this.utcMS > date.utcMS;
  }

  isBeforeOrEqual(date: AbsoluteDate) {
    return this.utcMS <= date.utcMS;
  }

  isAfterOrEqual(date: AbsoluteDate) {
    return this.utcMS >= date.utcMS;
  }

  isEqual(date: AbsoluteDate) {
    return (
      this.day === date.day &&
      this.month === date.month &&
      this.year === date.year
    );
  }

  toFormat(format: string) {
    return this.utcDateTime.toFormat(format);
  }

  /**
   * This is implicitly called by Apollo and JSON.stringify
   */
  toJSON(): string {
    return this.toISO();
  }

  toRange(context: time.DateContext): {
    start: DateTime;
    end: DateTime;
  } {
    return {
      start: this.toDateTime(context).startOf('day'),
      end: this.toDateTime(context).endOf('day'),
    };
  }

  toDateRange(context: time.DateContext): {
    start: Date;
    end: Date;
  } {
    const {start, end} = this.toRange(context);
    return {
      start: start.toJSDate(),
      end: end.toJSDate(),
    };
  }

  private get utcMS() {
    return this.toDateTime({timezone: 'UTC'}).toMillis();
  }

  private get utcDateTime() {
    return this.toDateTime({timezone: 'UTC'});
  }

  private validate() {
    const parsed = DateTime.fromObject({
      day: this.day,
      month: this.month,
      year: this.year,
    });

    if (!parsed.isValid) {
      throw new Error(
        `${this.year}-${this.month}-${this.day} is not a valid date`
      );
    }
  }
}
