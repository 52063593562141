import {z} from 'zod';
import {FormConfig} from '../../types';

export const scheduled_exports = {
  title: 'Exports',
  configType: 'biller',

  validation: z.object({
    exports: z.array(
      z.object({
        exportConfigItemId: z.string(),
        strategy: z.string(),
        schedule: z
          .union([z.string(), z.number()])
          .refine(
            value => {
              if (typeof value === 'string') {
                return !isNaN(Number(value));
              }
              return true;
            },
            {message: 'Must be a number'}
          )
          .transform(value => {
            if (typeof value === 'string') {
              return Number(value);
            }
            return value;
          })
          .pipe(z.number().int().min(0).max(23)),
      })
    ),
  }),

  form: [
    {
      type: 'list',
      name: 'exports',
      label: 'Daily Exports',
      fields: [
        {
          type: 'uuid',
          name: 'exportConfigItemId',
          label: 'Export Config Item ID',
          hidden: true,
        },
        {
          type: 'dropdown',
          name: 'strategy',
          label: 'Strategy',
          options: [
            'all_plans',
            'wingecarribee_all_plans',
            'daily_payments',
            'daily_failed_dd_payments',
            'pathway_cancelled_plans',
            'pathway_plans',
            'legacy_cancelled_plans',
            'legacy_created_plans',
            'civica_payment_receipt',
            'gateway_receipt_bpoint',
            'gateway_receipt_quickstream',
            'gateway_failure_dd_quickstream',
            'gateway_receipt_payway',
            't1_cancelled_plans',
            't1_debtor_note',
            't1_payment_plan',
            't1_payment_receipt',
            'payble_receipt_all_v1',
            'payble_receipt_dd_v1',
            'payble_receipt_card_v1',
            'civica_plan_arrangements',
            'mornpen_payment_receipt',
          ],
        },
        {type: 'number', name: 'schedule', label: 'Schedule hour (0-23)'},
      ],
      addItemLabel: 'Add Export',
    },
  ],
} satisfies FormConfig;
