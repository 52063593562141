import React, {ReactNode, useEffect, useMemo} from 'react';
import * as Sentry from '@sentry/react';
import {getBillerSlugFromUrl} from 'lib/url';
import {goBack} from 'lib/navigation/routes';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {getFeatureConfig} from 'payble-shared/src/biller-config/handlers';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Badge, Button, Form, Loading, useFormContext} from 'payble-ui';
import {
  AbsoluteDate,
  BillerConfig,
  FeatureConfig,
  formatToDollars,
} from 'payble-shared';
import {
  Account,
  InstalmentFrequency,
  InstalmentPlanMode,
} from 'lib/graphql/API';
import {getPaymentFrequencyOptions} from '../shared/PaymentFrequencyOptions';
import {Debbie} from 'components/organisms/Debbie';
import {z} from 'zod';
import {zAbsoluteDate} from 'payble-api-client/schemas';
import {useAPIQuery} from 'lib/api';
import {ErrorMessage} from 'components/atoms/ErrorMessage';

type FlexibleInstalments = {
  quarterly: number;
  weekly: number;
  fortnightly: number;
  monthly: number;
};

type AccountRange = {
  minimumRequiredPayments: {
    annually: number;
    quarterly: number;
    weekly: number;
    fortnightly: number;
    monthly: number;
    end_of_month: number;
  } | null;
  flexibleInstalmets: FlexibleInstalments | null;
};

type GetInstalmentForFrequency = {
  payEveryXInstalments: FlexibleInstalments;
  instalmentFrequency: InstalmentFrequency;
};

function getInstalmentForFrequency({
  payEveryXInstalments,
  instalmentFrequency,
}: GetInstalmentForFrequency) {
  if (
    instalmentFrequency === 'annually' ||
    instalmentFrequency === 'quarterly' ||
    instalmentFrequency === 'automatic'
  ) {
    throw new Error(`PAY_X_EVERY_Z does not support ${instalmentFrequency}`);
  }

  return payEveryXInstalments[instalmentFrequency];
}

type GetMinMax = {
  billerConfig: BillerConfig;
  featureConfig: FeatureConfig;
  accountRange: AccountRange;
  instalmentFrequency: InstalmentFrequency;
  account: Account;
};

type GetPaymentConfig = {
  minAmount: number;
  maxAmount: number;
  lockedAmount: boolean;
};

function getPaymentConfig({
  billerConfig,
  featureConfig,
  accountRange,
  instalmentFrequency,
  account,
}: GetMinMax): GetPaymentConfig | null {
  const {
    FLEXIBLE_INSTALMENTS_AMOUNT_MIN,
    FLEXIBLE_INSTALMENTS_AMOUNT_MAX,
    FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED,
  } = featureConfig;

  if (
    billerConfig.payXEveryZ?.requiresMinimumPaymentForAccountType.includes(
      account.type
    )
  ) {
    const minimumRequiredPayments = accountRange.minimumRequiredPayments;

    if (!minimumRequiredPayments) {
      throw new Error(
        `Biller ${billerConfig.billerId} requiresMinimumPayment but account is missing minimum required payments.`
      );
    }

    if (instalmentFrequency === 'automatic') {
      return null;
    }

    const instalmentMinimum = minimumRequiredPayments[instalmentFrequency];

    return {
      minAmount:
        instalmentMinimum < FLEXIBLE_INSTALMENTS_AMOUNT_MIN
          ? FLEXIBLE_INSTALMENTS_AMOUNT_MIN
          : instalmentMinimum,
      maxAmount: FLEXIBLE_INSTALMENTS_AMOUNT_MAX,
      lockedAmount: false,
    };
  }

  return {
    minAmount: FLEXIBLE_INSTALMENTS_AMOUNT_MIN,
    maxAmount: FLEXIBLE_INSTALMENTS_AMOUNT_MAX,
    lockedAmount: FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED,
  };
}

function getNextStepPayXEveryZ(
  instalmentStartAt: AbsoluteDate,
  amountInCents: number,
  instalmentFrequency: InstalmentFrequency
) {
  return {
    path: '/biller/:slug/setup/flexible/recovery-terms',
    params: {
      instalmentMode: InstalmentPlanMode.PayXEveryZ,
      mode: 'PAY_X_EVERY_Z',
      paymentStartDate: instalmentStartAt.toISO(),
      amountInCents: amountInCents.toString(),
      instalmentFrequency,
    },
  };
}

function getNextPath({
  billerConfig,
  accountRange,
  instalmentFrequency,
  amountInCents,
  instalmentStartAt,
  account,
}: {
  billerConfig: BillerConfig;
  accountRange: AccountRange;
  instalmentFrequency: InstalmentFrequency;
  amountInCents: number;
  instalmentStartAt: AbsoluteDate;
  account: Account;
}) {
  if (
    !billerConfig.payXEveryZ?.requiresMinimumPaymentForAccountType.includes(
      account.type
    )
  ) {
    return getNextStepPayXEveryZ(
      instalmentStartAt,
      amountInCents,
      instalmentFrequency
    );
  }

  const payEveryXInstalments = accountRange?.flexibleInstalmets;

  if (!payEveryXInstalments) {
    return getNextStepPayXEveryZ(
      instalmentStartAt,
      amountInCents,
      instalmentFrequency
    );
  }

  const payEveryXInstalment = getInstalmentForFrequency({
    payEveryXInstalments,
    instalmentFrequency,
  });

  return amountInCents >= payEveryXInstalment
    ? {
        path: '/biller/:slug/setup/plan/preview',
        params: {
          instalmentMode: InstalmentPlanMode.PayEveryX,
          mode: 'PAY_EVERY_X',
          instalmentFrequency,
          instalmentStartAt: instalmentStartAt.toISO(),
        },
      }
    : getNextStepPayXEveryZ(
        instalmentStartAt,
        amountInCents,
        instalmentFrequency
      );
}

type AlternativesProps = {
  accountRange: AccountRange;
};

const AlternativeLabel = ({
  amount,
  instalmentFrequency,
  badge,
}: {
  instalmentFrequency: string;
  amount: number;
  badge?: ReactNode;
}) => (
  <div className="flex items-center gap-2">
    <div className="text-lg">${formatToDollars(amount)}</div>
    <span>/{instalmentFrequency}</span>
    {badge}
  </div>
);

const Alternatives: React.FC<AlternativesProps> = ({accountRange}) => {
  const {getValues, setValue, watch} = useFormContext();
  const [instalmentFrequency, amountInCents, selectedAmountInCents] = getValues(
    ['instalmentFrequency', 'amountInCents', 'selectedAmountInCents']
  );
  const [paymentConfig] = watch(['paymentConfig']);

  const memOptions = useMemo(() => {
    const options: {
      id: string;
      value: number;
      label: React.ReactNode;
      description: string;
    }[] = [];

    const payEveryXInstalments = accountRange.flexibleInstalmets;
    const payEveryXInstalment = payEveryXInstalments
      ? getInstalmentForFrequency({
          payEveryXInstalments,
          instalmentFrequency,
        })
      : null;

    options.push({
      id: 'min',
      value: paymentConfig.minAmount,
      label: (
        <AlternativeLabel
          amount={paymentConfig.minAmount}
          instalmentFrequency={instalmentFrequency}
          badge={<Badge variant="peach">Minimum</Badge>}
        />
      ),
      description: `Payments made ${instalmentFrequency}`,
    });

    if (payEveryXInstalment) {
      const midOption = parseFloat(
        ((payEveryXInstalment + paymentConfig.minAmount) / 2).toFixed()
      );

      options.push({
        id: 'mid',
        value: midOption,
        label: (
          <AlternativeLabel
            amount={midOption}
            instalmentFrequency={instalmentFrequency}
          />
        ),
        description: `Payments made ${instalmentFrequency}`,
      });

      options.push({
        id: 'everything',
        value: payEveryXInstalment,
        label: (
          <AlternativeLabel
            amount={payEveryXInstalment}
            instalmentFrequency={instalmentFrequency}
            badge={<Badge variant="green">Pay everything</Badge>}
          />
        ),
        description: `Payments made ${instalmentFrequency}`,
      });
    }
    return options;
  }, [paymentConfig.minAmount, instalmentFrequency, accountRange]);

  // Sync radio value to input value
  useEffect(() => {
    if (selectedAmountInCents) {
      setValue('amountInCents', selectedAmountInCents);
    }
  }, [selectedAmountInCents]);

  // Sync input value to radio value
  const hasSameAmountOption = memOptions.some(
    ({value}) => amountInCents && amountInCents.toString() == value?.toString()
  );
  useEffect(() => {
    if (hasSameAmountOption) {
      setValue('selectedAmountInCents', amountInCents);
    } else {
      setValue('selectedAmountInCents', 0);
    }
  }, [hasSameAmountOption, amountInCents]);

  // EnsureThe options has changed
  useEffect(() => {
    if (!hasSameAmountOption) {
      setValue('selectedAmountInCents', memOptions[0].value);
    }
  }, [instalmentFrequency, hasSameAmountOption, memOptions]);

  return (
    <div>
      <Form.RadioGroup
        name="selectedAmountInCents"
        defaultValue={paymentConfig.minAmount}
        options={memOptions}
      />
    </div>
  );
};

const PaymentConfigUpdate = ({
  account,
  accountRange,
  featureConfig,
}: {
  account: Account;
  accountRange: AccountRange;
  featureConfig: FeatureConfig;
}) => {
  const billerConfig = useBillerConfig();
  const {watch, setValue} = useFormContext();
  const [instalmentFrequency] = watch(['instalmentFrequency']);

  useEffect(() => {
    setValue(
      'paymentConfig',
      getPaymentConfig({
        billerConfig,
        featureConfig,
        accountRange,
        instalmentFrequency,
        account,
      })
    );
  }, [instalmentFrequency, billerConfig, featureConfig, accountRange, account]);

  return null;
};

const UpdateInstalmentStartAt = () => {
  const {watch} = useFormContext();
  const [instalmentStartAt] = watch(['instalmentStartAt']);

  const {instalmentStartAt: instalmentStartAtFromParams, patch} =
    useSetupSearchParams();

  if (
    instalmentStartAtFromParams &&
    instalmentStartAtFromParams !== instalmentStartAt
  ) {
    patch({instalmentStartAt: instalmentStartAt.toISO()}, {replace: true});
  }

  return null;
};

export const FlexibleScheduleAmountFrequency = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentFrequency, amountInCents, instalmentStartAt, patch} =
    useSetupSearchParams();
  const frequencyOptions = getPaymentFrequencyOptions();

  const navigate = useSetupNavigate();

  const billerConfig = useBillerConfig();

  const today = AbsoluteDate.today({billerConfig});

  const {loading: loadingAccount, data: account} =
    useGetAccountFromSearchParams();

  const {data: accountRange, isPending} = useAPIQuery(
    'accountInstalmentRange',
    {
      data: account
        ? {
            accountId: account.id,
            startDate: instalmentStartAt ?? today,
          }
        : undefined,
    }
  );

  useEffect(() => {
    if (
      instalmentFrequency === 'annually' ||
      instalmentFrequency === 'quarterly' ||
      instalmentFrequency === 'automatic'
    ) {
      patch({
        instalmentFrequency: 'weekly',
      });
    }

    if (!instalmentStartAt) {
      patch({
        instalmentStartAt: AbsoluteDate.today({billerConfig}).toISO(),
      });
    }
  }, [instalmentFrequency, instalmentStartAt]);

  useEffect(() => {
    if (!accountRange && !isPending) {
      Sentry.captureException(
        `Unable to fetch account range for ${account.id} for biller ${billerSlug}.`
      );
    }
  }, [isPending]);

  const featureConfig = getFeatureConfig(billerSlug);

  if (loadingAccount || !account || isPending || !accountRange) {
    return <Loading />;
  }

  if (!accountRange && !isPending) {
    return (
      <>
        <ErrorMessage message="We are currently unable to calculate payments for your account, Please try again later." />

        <Button onClick={() => goBack()} variant="link" className="-mt-2">
          Back
        </Button>
      </>
    );
  }

  const paymentConfig = getPaymentConfig({
    billerConfig,
    featureConfig,
    accountRange,
    instalmentFrequency,
    account,
  });

  if (!paymentConfig) {
    return <Loading />;
  }

  return (
    <Form
      schema={z
        .object({
          instalmentFrequency: z.enum(['weekly', 'fortnightly', 'monthly']),
          amountInCents: z.number().min(1).max(paymentConfig.maxAmount),
          instalmentStartAt: zAbsoluteDate,
          showAlternative: z.boolean().optional(),
          paymentConfig: z.object({
            minAmount: z.number(),
            maxAmount: z.number(),
            lockedAmount: z.boolean(),
          }),
        })
        .superRefine(({showAlternative, amountInCents}, ctx) => {
          if (showAlternative && amountInCents < paymentConfig.minAmount) {
            ctx.addIssue({
              message: `Amount must be at least ${formatToDollars(paymentConfig.minAmount)}`,
              path: ['amountInCents'],
              code: 'custom',
            });
          }
        })}
      defaultValues={{
        instalmentFrequency,
        amountInCents,
        instalmentStartAt,
        showAlternative: false,
        paymentConfig,
      }}
      onSubmit={(values, form) => {
        if (!values.instalmentStartAt) return;

        if (values.amountInCents < paymentConfig.minAmount) {
          form.setValue('showAlternative', true);
          form.setValue('amountInCents', paymentConfig.minAmount);
          return;
        }

        const {path, params} = getNextPath({
          billerConfig,
          accountRange,
          instalmentFrequency: values.instalmentFrequency,
          amountInCents: values.amountInCents,
          instalmentStartAt: values.instalmentStartAt,
          account,
        });

        navigate(path, params);
      }}
    >
      <PaymentConfigUpdate
        accountRange={accountRange}
        account={account}
        featureConfig={featureConfig}
      />

      <UpdateInstalmentStartAt />

      <div className="flex flex-col gap-4">
        <Debbie
          title="Set up a payment plan"
          message="Enter your preferences to find a payment plan that suits you."
        />

        <div className="flex flex-col gap-2">
          <h4 className="font-semibold">First payment date</h4>
          <Form.InputDate
            name="instalmentStartAt"
            minDate={today}
            maxDate={today.plus({
              days: featureConfig.FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS,
            })}
          />
        </div>

        <div className="flex flex-col gap-2">
          <h4 className="font-semibold">Instalment Amount</h4>
          <Form.InputMoney
            name="amountInCents"
            min={paymentConfig.minAmount}
            max={paymentConfig.maxAmount}
            disabled={paymentConfig.lockedAmount}
          />
        </div>
      </div>

      <div>
        <Form.RadioGroup
          name="instalmentFrequency"
          options={frequencyOptions}
          variant="minimal"
          direction="horizontal"
        />
      </div>

      <Form.Connect>
        {({showAlternative}) =>
          showAlternative ? (
            <>
              <Alternatives accountRange={accountRange} />

              <Form.SubmitButton className="mt-2">
                Set up flexible payments
              </Form.SubmitButton>

              {!!billerConfig.support?.hardshipLink && (
                <div>
                  <p className="py-4 font-semibold text-center text-gray-800">
                    Unable to afford the minimum?
                  </p>
                  <Button
                    as="a"
                    variant="outline"
                    className="bg-blue-100"
                    href={billerConfig.support.hardshipLink}
                    target="_blank"
                  >
                    Apply for hardship assistance
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Form.SubmitButton className="mt-2">
              Find a payment plan for me
            </Form.SubmitButton>
          )
        }
      </Form.Connect>

      <Button onClick={() => goBack()} variant="link" className="-mt-2">
        Back
      </Button>
    </Form>
  );
};
