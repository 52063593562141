import {z} from 'zod';
import {zAbsoluteDate, zISODate} from './generic';
import {accountResponse} from './account';
import {paymentMethodResponse} from './contact';

export const planFrequency = z.enum([
  'weekly',
  'fortnightly',
  'monthly',
  'quarterly',
  'automatic',
  'annually',
]);

export type PlanFrequency = z.infer<typeof planFrequency>;

export const planPayMode = z.enum([
  'pay-every-x',
  'balanced-pay-every-x',
  'pay-on-y',
  'pay-x-on-y',
  'pay-x-every-z',
  'smooth-pay',
  'catch-up',
]);

export const instalmentType = z.enum(['regular', 'setup']);

export const instalmentBreakdown = z.object({
  amount: z.number(),
  label: z.string(),
});

export const planResponse = z.object({
  amount: z.number(),
  amountPaid: z.number(),
  amountDue: z.number(),
  instalments: z.array(
    z.object({
      instalmentId: z.string(),
      dueAt: zAbsoluteDate,
      status: z
        .enum([
          'cancelled',
          'missed',
          'overdue',
          'paid',
          'processing',
          'refunded',
          'scheduled',
          'skipped',
        ])
        .optional(),
      amount: z.number(),
    })
  ),
});

export type PlanResponseType = z.infer<typeof planResponse>;

export const instalmentSchema = z.object({
  instalmentId: z.string(),
  amount: z.number(),
  dueAt: zAbsoluteDate,
  paidAt: zISODate.nullable(),
  refundedAt: zISODate.nullable(),
  cancelledAt: zISODate.nullable(),
  missedAt: zISODate.nullable(),
  skippedAt: zISODate.nullable(),
  status: z.enum([
    'scheduled',
    'processing',
    'overdue',
    'paid',
    'refunded',
    'cancelled',
    'missed',
    'skipped',
  ]),
  type: instalmentType,
  breakdown: z.array(instalmentBreakdown).optional(),
});

export type InstalmentSchema = z.infer<typeof instalmentSchema>;

export const instalmentPlanResponse = z.object({
  id: z.string(),
  amount: z.number(),
  mode: planPayMode,
  status: z.enum([
    'pending',
    'on_track',
    'overdue',
    'processing',
    'completed',
    'cancelled',
  ]),
  frequency: z
    .enum([
      'weekly',
      'fortnightly',
      'monthly',
      'quarterly',
      'fixed',
      'end_of_month',
      'automatic',
      'annually',
    ])
    .optional(),
  instalments: z.array(instalmentSchema),
  nextInstalmentDueAt: zAbsoluteDate.optional(),
  cancelledReason: z.string(),
  targetDate: zAbsoluteDate.nullable(),
  paymentMethodId: z.string(),
  account: accountResponse,
  accountId: z.string(),
  amountDue: z.number(),
  amountPaid: z.number(),
  billerId: z.string(),
  createdAt: zISODate,
  cancelledAt: zISODate.nullable(),
  completedAt: zISODate.nullable(),
  currency: z.string(),
  testMode: z.boolean(),
  canUpdateInstalmentAmount: z.boolean(),
  tags: z.array(
    z.object({
      tagId: z.string(),
      label: z.string(),
      color: z.string(),
      internalKey: z.string().optional(),
    })
  ),
  contactPaymentMethod: paymentMethodResponse,
  events: z.array(
    z.object({
      id: z.string(),
      entityId: z.string(),
      type: z.string(),
      createdAt: zISODate,
    })
  ),
});

export type InstalmentPlanResponse = z.infer<typeof instalmentPlanResponse>;
