import {PaymentResponse} from 'payble-api-client/schemas/payment';
import {toTitleCase} from 'payble-shared';

export const getFriendlyPaymentStatus = (
  status: PaymentResponse['status']
): string => {
  if (status === 'succeeded') {
    return 'Successful';
  }
  return toTitleCase(status);
};
