import React, {useEffect, useState} from 'react';

import {getBillerSlugFromUrl} from 'lib/url';
import {navigate} from 'lib/navigation/routes';
import {useGetAuthKeyQuery} from 'lib/graphql/API';

import {Debbie} from 'components/organisms/Debbie';
import {RadioGroup} from '@headlessui/react';
import {ProfileMenu} from '../components/ProfileMenu';

import {HomeIcon} from '@heroicons/react/20/solid';

import {useLocation} from 'react-router';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {DirectDebit} from 'components/organisms/DirectDebit';
import {TokenExCreditCardForm} from 'components/organisms/TokenExCreditCardForm';
import {
  PAYMENT_METHODS,
  PaymentMethodPicker,
  PaymentType,
} from 'components/organisms/PaymentMethodPicker';
import {DirectDebitNZ} from '../../../components/organisms/DirectDebitNZ';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {MaxContainer} from 'payble-ui';
import {Loading} from 'components/atoms/Loading';
import {analytics} from '../../../analytics/hooks/useAnalytics';
import {useAPIMutation, useAPIQuery} from 'lib/api';
import {InstalmentPlanResponse} from 'payble-api-client/schemas';
import {AddPaymentMethodResponse} from 'payble-api-client/schemas/payment';
import {useUser} from 'lib/auth/useUser';

type RedirectAfterAddPaymentMethod = {
  data: AddPaymentMethodResponse;
  instalmentPlanId: string | null;
  billerSlug: string;
  navigate: typeof navigate;
  instalmentPlans: InstalmentPlanResponse[];
};

function redirectAfterAddPaymentMethod(
  type: 'card' | 'direct_debit' | 'nz_direct_debit',
  {
    data,
    instalmentPlanId,
    billerSlug,
    navigate,
    instalmentPlans,
  }: RedirectAfterAddPaymentMethod
) {
  const {paymentMethodId, updatedPlans} = data;

  analytics.addEvent('profile_payment_method_added', {type});

  if (instalmentPlanId && !updatedPlans.length) {
    const queryStringParams = new URLSearchParams('');
    queryStringParams.append('paymentMethodId', paymentMethodId);
    return navigate(
      '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
      {
        instalmentPlanId: instalmentPlanId,
        slug: billerSlug,
      },
      false,
      queryStringParams
    );
  }

  if (updatedPlans.length === 1 && updatedPlans[0]) {
    return navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
      instalmentPlanId: updatedPlans[0].id,
      slug: billerSlug,
    });
  }

  return navigate(
    instalmentPlans.length > 0 ? '/biller/:slug' : '/biller/:slug/profile',
    {slug: billerSlug}
  );
}

export const AddPaymentMethod: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const user = useUser();
  const queryParams = new URLSearchParams(useLocation().search);
  const instalmentPlanId = queryParams.get('instalmentPlanId');
  const billerConfig = useBillerConfig();

  const {data} = useAPIQuery('getInstalmentPlans', {
    query: {
      enabled: true,
      retry: 1,
    },
  });
  const {loading: loadingAuthKey} = useGetAuthKeyQuery();

  const [paymentType, setPaymentType] = useState<PaymentType | undefined>(
    PAYMENT_METHODS.filter(({type}) =>
      billerConfig.paymentMethods.includes(type)
    )[0]
  );

  useEffect(() => {
    document.title = 'Payble - Add Payment Method';
  }, []);

  const {
    mutateAsync: addPaymentMethod,
    isPending: addPaymentMethodLoading,
    error: addPaymentMethodError,
  } = useAPIMutation('addPaymentMethod');

  const onCardComplete: React.ComponentProps<
    typeof TokenExCreditCardForm
  >['onCardComplete'] = async ({card, usePaymentMethodForPlans}) => {
    const data = await addPaymentMethod({
      type: 'card',
      ...card,
      usePaymentMethodForPlans,
    });

    return redirectAfterAddPaymentMethod('card', {
      data,
      instalmentPlanId,
      billerSlug,
      navigate,
      instalmentPlans: user.instalmentPlans ?? [],
    });
  };

  const onBankComplete: React.ComponentProps<
    typeof DirectDebit
  >['onComplete'] = async ({bank, usePaymentMethodForPlans}) => {
    const data = await addPaymentMethod({
      type: 'direct_debit',
      ...bank,
      usePaymentMethodForPlans,
    });

    return redirectAfterAddPaymentMethod('direct_debit', {
      data,
      instalmentPlanId,
      billerSlug,
      navigate,
      instalmentPlans: user.instalmentPlans ?? [],
    });
  };

  const onNZBankComplete: React.ComponentProps<
    typeof DirectDebitNZ
  >['onComplete'] = async ({nzBank, usePaymentMethodForPlans}) => {
    const data = await addPaymentMethod({
      type: 'nz_direct_debit',
      accountName: nzBank.accountName,
      accountNumber: nzBank.accountNumber.toJSON(),
      usePaymentMethodForPlans,
    });

    return redirectAfterAddPaymentMethod('nz_direct_debit', {
      data,
      instalmentPlanId,
      billerSlug,
      navigate,
      instalmentPlans: user.instalmentPlans ?? [],
    });
  };

  let profileName = 'Your';
  if (user.contact?.givenName) {
    profileName = user.contact?.givenName;
    profileName = profileName?.endsWith('s')
      ? `${profileName}'`
      : `${profileName}'s`;
  }

  const account = data?.find(plan => plan.id === instalmentPlanId)?.account;

  if (addPaymentMethodError) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage={`${addPaymentMethodError.message}. Please try again by clicking below`}
        onClick={() => {
          navigate('/biller/:slug/profile', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <MaxContainer>
      <Debbie
        title={'Add a payment method'}
        message="You can add payment methods for ongoing or once off payments."
      />

      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() =>
                      navigate('/biller/:slug', {slug: billerSlug})
                    }
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate('/biller/:slug/profile', {
                        slug: billerSlug,
                      })
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {profileName} profile
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex pl-4">
          <ProfileMenu slug={billerSlug} />
        </div>
      </div>

      <div id="payment-section">
        <RadioGroup value={paymentType} onChange={setPaymentType}>
          <PaymentMethodPicker
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            billerSlug={billerSlug}
            accountType={account?.type}
          />
        </RadioGroup>

        <div
          className={
            paymentType?.type === 'nz_direct_debit' ? 'min-w-full' : 'hidden'
          }
        >
          <DirectDebitNZ
            onComplete={onNZBankComplete}
            contact={user.contact}
            disabled={addPaymentMethodLoading}
            mode={'add'}
          />
        </div>

        <div
          className={
            paymentType?.type === 'direct_debit' ? 'min-w-full' : 'hidden'
          }
        >
          <DirectDebit
            onComplete={onBankComplete}
            contact={user.contact}
            disabled={addPaymentMethodLoading}
            mode={'add'}
          />
        </div>
        <div className={paymentType?.type === 'card' ? 'min-w-full' : 'hidden'}>
          {loadingAuthKey ? (
            <Loading />
          ) : (
            <TokenExCreditCardForm
              onCardComplete={onCardComplete}
              contact={user.contact}
              disabled={addPaymentMethodLoading}
              mode={'add'}
            />
          )}
        </div>
      </div>
    </MaxContainer>
  );
};
